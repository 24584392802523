import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Quemsomos from './pages/quemsomos.page/Quemsomos';
import Navhome from './components/navbar.component/NavHome';
import Footer from './components/footer.component/Footer';
import Notfound from './pages/notfound.page/Notfound';
import Termos from './pages/termos.page/Termos';
import Politica from './pages/politica.page/Politica';
import Jerusalem from './pages/pacote.page/jerusalem/Index';
import Empresa from './pages/pacote.page/empresa/Index'
import Brasil from './pages/pacote.page/brasil/Index'
import Pacotes from './pages/pacotes.page/Pacotes';
import Pacote from './pages/pacote.page/pacote/Index';

//import Admin from './pages/admin.page/Admin';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Navbar /> */}
        <Navhome />
        
        <Routes>
          {/* <Route path='/' element={<Home />}/> */}
          {/* <Route path='/promocao/passagens' element={<Home />}/> */}
          <Route path='/' element={<Pacotes />}/>
          <Route path='/home' element={<Pacotes />}/>
          {/* <Route path="/minha-conta/" element={<Login />} /> */}
          <Route path="/brazil" element={<Brasil />} />
          <Route path="/pacote/jerusalem" element={<Jerusalem />} />
          <Route path="/pacote/israel" element={<Jerusalem />} />
          <Route path="/pacote/empresa" element={<Empresa />} />
          <Route path="/pacote/:pacote" element={<Pacote />} />
          {/* <Route path="/passagem/:url" element={<Passagem />} /> */}
          {/* <Route path="/passagem/pedido/:url" element={<Pedido />} /> */}
          {/* <Route path="/passagem/:url/checkout" element={<Finalizar />} /> */}
          <Route path="/quemsomos" element={<Quemsomos />} />
          <Route path="/quemsomos/termos-e-condicoes" element={<Termos />} />
          {/* <Route path='/painel/admin' element={<Admin />}/> */}
          <Route path="/quemsomos/politica-de-privacidade" element={<Politica />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
        
        <Footer />
      </BrowserRouter> 
    </div>
   
  );
}

export default App;