import React from 'react'
import { useNavigate } from 'react-router-dom'


import './index.css'
const Notfound = () => {
    const navigate = useNavigate()
  return (
    <div className='notf'>
        <div>
            <h1>Vissssh, não encontramos o que você buscava . . .</h1>
            <img src="https://i.ibb.co/4TW6SQ9/notfound.png" alt="" /> <br />
            <button onClick={(e) => navigate("/home")}>Voltar para as promoções</button>
        </div>
    </div>
  )
}

export default Notfound