import { useEffect } from 'react'
import './index.css'


const Jerusalem = () => {

  const enviarMsg = (e) => {
    e.preventDefault()
    const msg = 'Olá, gostaria de saber sobre o pacote de viagem para empresas.'
    window.open(`https://wa.me/5511915638609?text=${msg}`, "_blank");
  }

  useEffect(() => {
    let msg = 'Pacote nacionais e internacionais para empresas', desc = 'Tenha os melhores preços para eventos, conveções e trabalho para sua empresa.'
    
    document.querySelector('head meta[property="og:title"]').setAttribute('content', msg)

    document.querySelector('head meta[property="og:image"]').setAttribute('content', 'https://cdn.folhape.com.br/img/pc/1100/1/dn_arquivo/2018/06/centro-de-convencoes.jpg')

    document.querySelector('head meta[property="og:description"]').setAttribute('content', desc)

    const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
    document.querySelector('head meta[property="og:url"]').setAttribute('content', url)

  }, [])
  

  return (
    <div className='empresas'>
      <header className="parallax-header">
        <div className="container">
          <div className="header-content">
            <h3>Precisa de<span className="enfase-color"> passagem e hospedagem</span>  para sua empresa?</h3>
            <h4>Encontre os melhores preços aqui.</h4>
            <a  href="" className='nav-link'  onClick={enviarMsg} title="Via whatsapp">
              Faça sua cotação
            </a>
          </div>
        </div>
      </header>


      <main>
        <div className='content'id="saibamais">
          <h2>PARA EMPRESAS</h2>
        </div>
        <article>
          <section className='preco'>
            <p>VIAGENS</p>
            <p>nacionais </p>
            <p>e <span className="text-enfase">internacionais</span></p>

          </section>
          <section className='inclui'>
            <div>
              <p>Viagens para eventos, convenções, trabalho e etc.</p>
              <ul>
                <li>Para sócios</li>
                <li>Colobaradores</li>
                <li>e Grupos</li>
              </ul>
            </div>
            <div>
              <h3>Pacote inclui</h3>
              <ul>
                <li>Hotel</li>
              </ul>
              <h3>Pacote não inclui</h3>
              <ul>
                <li>Seguro viagem</li>
                <li>Gastos extras</li>
              </ul>
            </div>
          </section>

        </article>
        <div className='button-link'>
          <a href="" className='nav-link' onClick={enviarMsg} title="Faça sua cotação">
            Entre em contato
          </a>
        </div>
        
      </main>

      <button onClick={enviarMsg}><img src="/img/whatsapp.webp" alt="Entre em contato por WhatsApp" title='Entre em contato via WhatsApp' /></button>
    </div>
  )
}

export default Jerusalem