import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'

const Navbar = () => {
  return (
    <footer className='foot'>
        <div className="content-inst">
            <p className='ft-title'>Airbridge asas para o futuro!</p>
        </div>
        <div className="content-links">
            <ul>
                <h3>Contato</h3>
                <li><a href="https://www.instagram.com/airbridgeoficial/" target="_blank" >Instagram</a></li>
                <li><a href="https://wa.me/5511915638609" target="_blank" >Whatsapp</a></li>
                <li><a href="https://wa.me/5511915638609" target="_blank" >Ajuda e Dúvidas</a></li>
                <li><a href="https://wa.me/5511915638609" target="_blank" >Central de Ajuda</a></li>
            </ul>

            <ul>
                <h3>Informação</h3>
                <li>CNPJ: 25.203.723/0001-19</li>
                <li><small>Cadastrado no Ministério do Turismo Cadastur</small></li>
                <li><Link to="/quemsomos">Quem Somos</Link></li>
                <li><Link to="/quemsomos/termos-e-condicoes">Termos e Condições</Link></li>
                <li><Link to="/quemsomos/politica-de-privacidade">Política de Privacidade</Link></li>
                
            </ul>

        </div>

        <div className="content-img">
            <div>
                <img src="/img/selo-ssl.webp" alt="" />
            </div>
            <div>
                <img src="/img/cadastur-logo.webp" alt="" />
            </div>
            <div>
                <img src="/img/selo-turismo.webp" alt="" />
            </div>
        </div>
    </footer>
  )
}

export default Navbar