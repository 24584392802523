import './index.css'
import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <header className='nav' style={{ background: 'none', boxShadow: 'none'}}>
        <div className="content-img">
            <Link to="/" >
                <img src="/img/logo.webp" alt="" />
            </Link>
        </div>
        <div className="content-links">
            <ul>
                <li></li>
            </ul>
        </div>
    </header>
  )
}

export default Navbar
