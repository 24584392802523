import React from 'react'


import './index.css'
const Termos = () => {
  return (
    <div className='termos'>
        <div>
            <h1>Termos e condições</h1>
            <div className='ler'>
              <h3>Bagagem</h3>
              <p>Sua mala de mão deve pesar até 10kg para ser acomodada no bagageiro do avião. No bilhete não há inclusão de despacho de bagagens. A compra desse serviço deve ser realizada diretamente com a companhia aérea.</p>

              <h3>Observação</h3>
              <p>Por ser um voo promocional, a Airbridge não realiza quaisquer alterações como: mês de viagem escolhido, troca da cidade de origem ou de destino e venda do despacho de bagagens extras.</p>
              <p>Este voo não inclui transfer</p>

              <h3>Taxas de serviço</h3>
              <p>O valor pago inclui as passagens de ida e volta e taxas de embarque. Demais taxas e impostos locais ou de despacho de bagagens, se aplicáveis, deverão ser acertadas diretamente com as empresas e/ou órgãos públicos responsáveis.</p>

              <h3>Bebês de até 1 ano e 11 meses completos no período total da viagem</h3>
              <p>Voos Nacionais: Bebês não pagam.</p>
              <p>Voos Internacionais: Será cobrada uma taxa de 10% do valor da passagem de um adulto.</p>

              <h3>Crianças a partir de 2 anos</h3>
              <p>
                Para crianças a partir de 2 anos, é necessária a compra de um voo igual ao do adulto, tanto
                para voos nacionais quanto internacionais. </p>
                <p>
                Um pedido que contiver uma criança, deverá conter também um adulto. Caso o pedido contenha
                apenas 1 criança, este não poderá ser emitido.</p>
                <p>
                É imprescindível o preenchimento dos dados do bebê e da criança no “Formulário da viagem”
                (nome completo, data de nascimento e CPF), para efetivar a emissão do bilhete.
                </p>
                <p>
                <strong>O voo não é valido </strong> para a alta temporada: em semanas com feriados ou eventos festivos nas cidades de origem e destino.
                </p>

                <h3>Emissão da sua passagem</h3>
                <p>
                Após a compra, em até 20 dias, enviaremos por e-mail um formulário para preenchimento dos
dados dos passageiros, assim como eventuais observações. Caso não receba o formulário
dentro do prazo de 20 dias após a compra, é de responsabilidade do cliente acionar a Airbridge
para solicitar um novo envio.
                </p>

                <p>
                A passagem somente será emitida caso os dados dos passageiros estejam preenchidos
corretamente, no link do formulário enviado via e-mail, com antecedência mínima de 60 dias da
data solicitada para a viagem. O não preenchimento a tempo implicará no cancelamento do
pedido e não emissão da passagem.
                </p>

                <p>
                Para voos nacionais <strong>é exigido o CPF de todos os passageiros, inclusive bebês e crianças.
Para voos internacionais  pode ser exigido passaporte </strong>, podendo ser impeditivo para
embarque.
                </p>
                <p>
                Se houver alguma divergência nos dados dos passageiros, ou a falta de algum documento, o
cliente será informado pela equipe de emissão. Após esse contato, o pedido será cancelado no
prazo de 24 horas caso o cliente não retorne e será aplicada multa conforme política de
cancelamento.
                </p>

                <p>
                Após o recebimento do formulário devidamente preenchido enviaremos os dados sobre os voos
emitidos em até 30 dias antes da data escolhida. Fique de olho no seu e-mail, inclusive caixa de
spam, e whatsapp, pois informaremos os dados da sua viagem através desses canais.

                </p>
                <h3>Documentação para Viagem</h3>
                <p>
                É de exclusiva responsabilidade de quem vai viajar ter toda a documentação necessária em dia.
A Airbridge não se responsabiliza pela impossibilidade de ingresso na cidade de destino ou
escalas/conexões pela falta de qualquer documento dos viajantes. Pesquise com antecedência
tudo o que é exigido no lugar de destino. Alguns dos possíveis documentos necessários são:

                </p>
                <p>
                Cadastro de Pessoas Físicas (CPF), Passaporte, Visto, Carteira de Identidade (RG) ou Carteira
Nacional de Habilitação (CNH) válida e com foto.

                </p>

                <h3>Regras de cancelamento</h3>
                <p>Confira as regras:</p>

                <p><strong>
                Até 7 dias após a compra:</strong> sem multa.
Após 7 dias da compra: multa de 20%.</p>
<p><strong>
Após a emissão do bilhete:</strong> multa de 100%. Nesse caso, não haverá reembolso, devido ao
valor já pago aos parceiros.</p>
<p><strong>
Não comparecimento (no-show):</strong> multa de 100%, com os serviços considerados utilizados e
sem a devolução do valor.</p>
<p>
      O cancelamento do pedido pode ser solicitado pelo nosso e-mail: Comercial@airbridge.com.br

                </p>

                <h3>Reembolso</h3>
                <p>
                Ao solicitar seu cancelamento, o reembolso devido será feito, exclusivamente, na forma de
<strong>VOUCHER</strong>. A partir da data do cancelamento, você terá até <strong>dezembro de 2024 </strong> para utilizá-lo
(em um único pedido) para compras de voos ou pacotes (exceto produtos PROMO) através dos
canais de venda da Airbridge
                </p>
                <p>*A regra é válida para pedidos realizados a partir de 10/10/22</p>
            </div>
        </div>
    </div>
  )
}

export default Termos