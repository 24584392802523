import { Link } from 'react-router-dom'
import './index.css'
const City = ({ city }) => {

  const letterPais = city.pais.length > 24 ? true : false
  const letterDescription = city.description.length > 15 ? true : false
  return (
    <div className='city'>
      <Link to={city.url} city={city}>
        <div className="title-city">
          <h2>{city.nome}</h2>
          {letterPais
            ?
            <h3 style={{ fontSize: '0.9em', margin: '2px', padding: '0px' }}>{city.pais}</h3>
            :
            <h3>{city.pais}</h3>
          }
          {letterDescription
            ?
            <p style={{ fontSize: '0.9em' }}>{city.description}</p>
            :
            <p>{city.description}</p>
          }
          <Link to={city.url} city={city} className='btn-compre'>{city.btn || 'Compre já'} </Link>
        </div>
        <img src={"img/" + city.img} alt="" />
      </Link>
    </div>
  )
}

export default City