// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quem{
    width: 80%;
    padding: 20px;
    padding-top: 8%;
    background-color: white;
    border-radius: 10px;
    margin: auto;
    text-align: center;
}

.quem h1{
    font-size: 3em;
    margin: 20px;
}

.quem p{
    text-align: left;
    padding: 10px;
    font-size: 1.1em;
}

.quem span{
    text-align: center;
    padding: 10px;
    font-size: 2em;
    
}


`, "",{"version":3,"sources":["webpack://./src/pages/quemsomos.page/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,cAAc;;AAElB","sourcesContent":[".quem{\n    width: 80%;\n    padding: 20px;\n    padding-top: 8%;\n    background-color: white;\n    border-radius: 10px;\n    margin: auto;\n    text-align: center;\n}\n\n.quem h1{\n    font-size: 3em;\n    margin: 20px;\n}\n\n.quem p{\n    text-align: left;\n    padding: 10px;\n    font-size: 1.1em;\n}\n\n.quem span{\n    text-align: center;\n    padding: 10px;\n    font-size: 2em;\n    \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
