// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../config.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notf{
    width: 80%;
    padding: 20px;
    padding-top: 15%;
    background-color: white;
    border-radius: 10px;
    margin: auto;
    text-align: center;
}

.notf h1{
    font-size: 3em;
}

.notf img{
    margin: 10px;
    width: 70%;
    height: auto;
}

.notf button{
    font-size: 1.2em;
    padding: 10px;
    outline: none;
    background-color: red;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 3px;
}

@media (min-width: 500px) {

}

@media (min-width: 720px) {
    .notf{
        width: 80%;
        padding: 20px;
        padding-top: 10%;
        background-color: white;
        border-radius: 10px;
        margin: auto;
        text-align: center;
    }
    
    .notf h1{
        font-size: 3em;
    }
    
    .notf img{
        margin: 10px;
        width: 70%;
        height: auto;
    }
    
    .notf button{
        font-size: 1.2em;
        padding: 10px;
        outline: none;
        background-color: red;
        color: white;
        cursor: pointer;
        border: none;
        border-radius: 3px;
    }
}


@media (min-width: 900px) {

}

@media (min-width: 1200px) {

}
`, "",{"version":3,"sources":["webpack://./src/pages/notfound.page/index.css"],"names":[],"mappings":"AAEA;IACI,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,qBAAqB;IACrB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA;;AAEA;;AAEA;IACI;QACI,UAAU;QACV,aAAa;QACb,gBAAgB;QAChB,uBAAuB;QACvB,mBAAmB;QACnB,YAAY;QACZ,kBAAkB;IACtB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,YAAY;QACZ,UAAU;QACV,YAAY;IAChB;;IAEA;QACI,gBAAgB;QAChB,aAAa;QACb,aAAa;QACb,qBAAqB;QACrB,YAAY;QACZ,eAAe;QACf,YAAY;QACZ,kBAAkB;IACtB;AACJ;;;AAGA;;AAEA;;AAEA;;AAEA","sourcesContent":["@import url(../../config.css);\n\n.notf{\n    width: 80%;\n    padding: 20px;\n    padding-top: 15%;\n    background-color: white;\n    border-radius: 10px;\n    margin: auto;\n    text-align: center;\n}\n\n.notf h1{\n    font-size: 3em;\n}\n\n.notf img{\n    margin: 10px;\n    width: 70%;\n    height: auto;\n}\n\n.notf button{\n    font-size: 1.2em;\n    padding: 10px;\n    outline: none;\n    background-color: red;\n    color: white;\n    cursor: pointer;\n    border: none;\n    border-radius: 3px;\n}\n\n@media (min-width: 500px) {\n\n}\n\n@media (min-width: 720px) {\n    .notf{\n        width: 80%;\n        padding: 20px;\n        padding-top: 10%;\n        background-color: white;\n        border-radius: 10px;\n        margin: auto;\n        text-align: center;\n    }\n    \n    .notf h1{\n        font-size: 3em;\n    }\n    \n    .notf img{\n        margin: 10px;\n        width: 70%;\n        height: auto;\n    }\n    \n    .notf button{\n        font-size: 1.2em;\n        padding: 10px;\n        outline: none;\n        background-color: red;\n        color: white;\n        cursor: pointer;\n        border: none;\n        border-radius: 3px;\n    }\n}\n\n\n@media (min-width: 900px) {\n\n}\n\n@media (min-width: 1200px) {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
