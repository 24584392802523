export default [
    // Asia
    {
        id: 101,
        urlName: 'japao',
        url: '/pacote/japao',
        nome: 'Japão',
        pais: 'Tóquio + Osaka + Kyoto',
        img: 'japao.webp',
        description: '14 dias',
        valor: '27.500',
        dias: [
            { id: 1, title: 'Saída de SP', items: ['Embarque em Guarulhos.'] },
            { id: 2, title: 'Chegada em Tóquio (Japão)', items: ['Chegada em Tóquio no Japão.', 'Nossa equipe estará esperando você no desembarque com a placa “Airbridge Experience” e levará para o hotel para descansar da viagem.'] },
            { id: 3, title: 'Tóquio', items: ['Iniciando o dia de Tour por Shibuya e Harajuku , seguindo para Yoyogi Parque. Passando pelo Santuário Meiji -Jingu , continuando o passeio pela região de Minato (Torre Tokyo Tower ). Visitando lugares incríveis e conhecendo melhor a história da Cidade.', 'O tour tem em torno de 6 à 8 horas de duração.'] },
            { id: 4, title: 'Tóquio', items: ['Hoje iniciaremos o Tour pela região Akihabara , repleta de lojas por todo lado e considerado o berço do eletrônico no Japão.Akihabara também tem muitas lojas voltada ao publico que gosta de animes , e é considerada o melhor lugar para comprar eletrônicos com preços especiais. Em seguida , conheceremos a região de Asakusa , caminharemos pela galeria Nakamiise Street, com lojinhas de petiscos tradicionais japoneses e visitando famoso templo Senso-ji ,ao partir para o próximo destino , avistaremos a Torre Sky Tree , nosso destino oficial será a Ilha de Odaiba, a ilha artificial de Tokyo.', 'O tour tem em torno de 6 à 8 horas de duração.'] },
            { id: 5, title: 'Monte Fuji', items: ['Monte Fuji , suba até a 5ª estação do Monte Fuji, a 2.000 metros de altitude (sujeito a condição climática). Visite o Lago Kawaguchiko , Oshino Hakkai , Santuário Sengen e Pagoda Chureit. Fuji é um lugar muito especial e será uma viagem incrível.', 'O tour tem em torno de 10h horas de duração com o tempo de transporte e visitas. Esse tour é feito de carro até Fuji e depois retornamos para Tokyo.'] },
            { id: 6, title: 'Osaka', items: ['Vamos dar início ao nosso passeio pegando o famosos “Trem bala” em direção a Osaka.', 'Neste dia iremos ao Castelo de Osaka , Torre Tsutenkaku , onde poderão subir no mirante da torre e Dotombori famoso por sua iluminação noturna e por ser grande e movimentado de pessoas em busca de lojas e restaurantes e entretenimento!', 'O tour tem em torno de 10h horas de duração com o tempo de transporte e visitas. Esse tour é feito de carro e o pernoite será em Osaka.'] },
            { id: 7, title: 'Osaka', items: ['Dia livre em Osaka para curtir a Cidade à sua maneira, andar por ela, ir aos restaurantes de sua preferência. O pernoite se dará em Osaka neste dia.'] },
            { id: 8, title: 'Kyoto', items: ['Iremos de trem de Osaka para Kyoto. Nesse dia vamos para Kyomizudera , ruas Ninenzaka e Sannensaka repleta de lojinhas tradicionais . Santuário Yasaka e Fujimi Inari Taisha ,retornando o Guia no final do dia para o hotel.', 'Kinkaku-ji ( Pavilhão Dourado ) e a Floresta de Bambu em Arashiyama , retornando para Osaka visitando a região de Umeda com Shoppings , restaurantes e lojas elegantes.', 'O tour tem em torno de 8 horas de duração e o pernoite será em Kyoto.'] },
            { id: 9, title: 'Kyoto', items: ['Dia livre em Kyoto para curtir a Cidade à sua maneira, andar por ela, ir aos restaurantes de sua preferência. Kyoto tem se destacado com um grande centro de gastronomia. O pernoite se dará em kyoto neste dia.'] },
            { id: 10, title: 'Hiroshima', items: ['Saída pela manhã, com destino a Hiroshima.', 'Seja recepcionado por nosso guia e inicie seu passeio.', 'Visite o Parque Memorial da Paz de Hiroshima. Veja o Salão de Promoção Industrial da Prefeitura de Hiroshima, hoje conhecido como Cúpula da Bomba Atômica, que foi um dos poucos prédios que sobreviveram ao bombardeio atômico de Hiroshima em 6 de agosto de 1945. Hoje, o prédio serve como um memorial às vítimas do bombardeio e como um símbolo da paz internacional. Visite o Museu Memorial da Paz de Hiroshima no local e saiba mais sobre o bombardeio. Veja artefatos comoventes da época da explosão atômica em exibição, bem como várias exposições educacionais.', 'O tour tem em torno de 8 horas de duração, e o pernoite será em Kyoto para onde retornaremos.'] },
            { id: 11, title: 'Kyoto para Tóquio', items: ['Retorno para Tóquio de trem.'] },
            { id: 12, title: 'Tóquio', items: ['Dia livre em Tóquio para curtir a Cidade à sua maneira e aproveitar para desfrutar a Cidade.'] },
            { id: 13, title: 'Tóquio', items: ['Dia livre em Tóquio para curtir a Cidade à sua maneira e aproveitar para desfrutar a Cidade.'] },
            { id: 14, title: 'Voo para SP', items: ['Voo para Guarulhos'] },
        ],
        incluso: [ '    Transfer', 'hospedagem com Hotel + Café da manhã. 3 e 4 estrelas', 'Tickets para os passeios e transportes', 'Guia em português'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },

    {
        id: 102,
        urlName: 'china',
        url: '/pacote/china',
        nome: 'China',
        pais: 'Pequim à Hong Kong',
        img: 'china.webp',
        description: '10 dias',
        valor: '22.500',
        dias: [
            { id: 1, title: 'Pequim', items: ['Chegada em Pequim.', 'Transfer para o hotel.'] },
            { id: 2, title: 'Pequim', items: ['Inicie seu Tour fazendo um passeio pela Cidade,  Visite a Cidade Proibida e a Praça Tiananmen,  A Cidade Proibida foi o palácio imperial da China desde meados da Dinastia Ming até ao fim da Dinastia Qing. Fica localizada no centro da antiga cidade de Pequim.', 'Visite o incrível Templo do Céu que foi um complexo de templos taoístas em Pequim, o maior do seu género em toda a República Popular da China. Foi construído no ano 1420.'] },
            { id: 3, title: 'Pequim', items: ['Encontre seu guia no Hotel e siga para a Grande Muralha de Mutianyu, que levará cerca de 1,5 horas, seu guia explicará as histórias relacionadas à Muralha no caminho, sentará e apreciará as paisagens do campo ao longo do caminho.', 'Ao chegar à Grande Muralha de Mutianyu, você pegará o teleférico ou teleférico até a Muralha, passará algum tempo subindo e descendo por cerca de 1,5 horas nas várias seções da Muralha, explorando as torres de observação e farol. ', 'Depois, pare em um restaurante local para almoçar. À tarde, continue a explorar um jardim real bem preservado: o Palácio de Verão. Siga para passear pelo jardim, aprenda as histórias por trás dos edifícios. Conheça a famosa "Dragon Lady" e sua vida no Palácio de Verão. Após o término do passeio, volte ao seu hotel.'] },
            { id: 4, title: 'Ida para Xian', items: ['Ida de Trem para Xian.', 'Ida para o Hotel.', 'Tarde para noite livre.'] },
            { id: 5, title: 'Xian', items: ["Descubra os artefatos de uma das escavações arqueológicas mais importantes do mundo nesta viagem de Xi'an. Parta de Xi'an e desfrute de um passeio até o Museu do Mausoléu do Imperador Qinshihuang, que abriga mais de 7.000 guerreiros de terracota, com mais de 2.000 anos de idade.", "Visite a Muralha da Cidade, como marco da antiga capital Xian, é um local imperdível para os viajantes. Seu guia turístico profissional dará vida à gloriosa história de Xi'an neste passeio. - Seu guia irá apresentá-lo à fascinante história e cultura desta cidade antiga durante a caminhada até a Muralha da Cidade. - A Muralha da Cidade de Xi'an é a maior e mais bem preservada muralha da cidade antiga da China. Serviu como sistema defensivo militar nos tempos antigos, que pode ser rastreado até a Dinastia Tang. Aprecie as vistas panorâmicas da cidade e veja 600 anos de história passando por você."] },
            { id: 6, title: 'Ida para Xangai', items: ['Aéreo para Xangai logo pela manhã.', 'Explore o Bund e admire a arquitetura colonial europeia.', 'Visite o Templo do Deus da Cidade (Chenghuang Miao) e o Jardim Yuyuan.', 'Passeie pela moderna área de Pudong e suba na Torre Pérola Oriental para vistas panorâmicas da cidade.'] },
            { id: 7, title: 'Disney Xangai', items: ['Este dia será destinado à Disney.', 'Ingresso incluso para a atração.'] },
            { id: 8, title: 'Ida para Hong Kong ', items: ['Ida para o Hotel', 'Tarde e noite livre'] },
            { id: 9, title: 'Ida para Hong Kong', items: ['Aéreo para Hong Kong logo pela manhã.', 'Após a chegada, enviaremos as bagagens para o hotel e já iniciaremos um passeio pela vibrante região de Central e Admiralty, onde você pode visitar o famoso Victoria Peak para uma vista deslumbrante da cidade.', 'Iremos visitar o icônico Tian Tan Buddha em Lantau Island', 'Em seguida, explore os mercados de rua em Mong Kok e experimente a deliciosa culinária'] },
            { id: 10, title: 'Retorno para SP', items: ['Voo para Guarulhos'] },
        ],
        incluso: ['hospedagem com Hotel 3 ou 4 estrelas + Café da manhã.', 'Tickets para as atrações', 'Guia em português'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },
  
    {
        id: 103,
        urlName: 'japao-coreia-do-sul',
        url: '/pacote/japao-coreia-do-sul',
        nome: 'Ásia',
        pais: 'Japão + Coreia do Sul',
        img: 'japao-coreia.webp',
        description: '22 dias',
        valor: '37.610',
        dias: [
            { id: 1, title: 'Saída de SP para Dubai', items: ['Embarque em Guarulhos e desembarque em Dubai no mesmo dia.', 'Inclui transfer na chegada e na partida, hotel com café da manhã em Dubai.'] },
            { id: 2, title: 'Chegada em Tóquio (Japão)', items: ['Chegada em Tóquio no Japão.', 'Nossa equipe estará esperando você no desembarque com a placa “Airbridge Experience” e levará para o hotel para descansar da viagem.'] },
            { id: 3, title: 'Tóquio', items: ['Iniciando o dia de Tour por Shibuya e Harajuku , seguindo para Yoyogi Parque. Passando pelo Santuário Meiji -Jingu , continuando o passeio pela região de Minato (Torre Tokyo Tower ). Visitando lugares incríveis e conhecendo melhor a história da Cidade.', 'O tour tem em torno de 6 à 8 horas de duração.'] },
            { id: 4, title: 'Tóquio', items: ['Hoje iniciaremos o Tour pela região Akihabara , repleta de lojas por todo lado e considerado o berço do eletrônico no Japão.Akihabara também tem muitas lojas voltada ao publico que gosta de animes , e é considerada o melhor lugar para comprar eletrônicos com preços especiais. Em seguida , conheceremos a região de Asakusa , caminharemos pela galeria Nakamiise Street, com lojinhas de petiscos tradicionais japoneses e visitando famoso templo Senso-ji ,ao partir para o próximo destino , avistaremos a Torre Sky Tree , nosso destino oficial será a Ilha de Odaiba, a ilha artificial de Tokyo.', 'O tour tem em torno de 6 à 8 horas de duração.'] },
            { id: 5, title: 'Monte Fuji', items: ['Monte Fuji , suba até a 5ª estação do Monte Fuji, a 2.000 metros de altitude (sujeito a condição climática). Visite o Lago Kawaguchiko , Oshino Hakkai , Santuário Sengen e Pagoda Chureit. Fuji é um lugar muito especial e será uma viagem incrível.', 'O tour tem em torno de 10h horas de duração com o tempo de transporte e visitas. Esse tour é feito de carro até Fuji e depois retornamos para Tokyo'] },
            { id: 6, title: 'Osaka', items: ['Vamos dar início ao nosso passeio pegando o famosos “Trem bala” em direção a Osaka.', 'Neste dia iremos ao Castelo de Osaka , Torre Tsutenkaku , onde poderão subir no mirante da torre e Dotombori famoso por sua iluminação noturna e por ser grande e movimentado de pessoas em busca de lojas e restaurantes e entretenimento!', 'O tour tem em torno de 10h horas de duração com o tempo de transporte e visitas. Esse tour é feito de carro e o pernoite será em Osaka.'] },
            { id: 7, title: 'Osaka', items: ['Dia livre em Osaka para curtir a Cidade à sua maneira, andar por ela, ir aos restaurantes de sua preferência. O pernoite se dará em Osaka neste dia.'] },
            { id: 8, title: 'Kyoto ', items: ['Iremos de trem de Osaka para Kyoto. Nesse dia vamos para Kyomizudera , ruas Ninenzaka e Sannensaka repleta de lojinhas tradicionais . Santuário Yasaka e Fujimi Inari Taisha ,retornando o Guia no final do dia para o hotel.', 'Kinkaku-ji ( Pavilhão Dourado ) e a Floresta de Bambu em Arashiyama , retornando para Osaka visitando a região de Umeda com Shoppings, restaurantes e lojas elegantes.', 'O tour tem em torno de 8 horas de duração e o pernoite será em Kyoto.'] },
            { id: 9, title: 'Kyoto', items: ['Dia livre em Kyoto para curtir a Cidade à sua maneira, andar por ela, ir aos restaurantes de sua preferência. Kyoto tem se destacado com um grande centro de gastronomia. O pernoite se dará em kyoto neste dia.'] },
            { id: 10, title: 'Hiroshima', items: ['Saída pela manhã, com destino a Hiroshima.', 'Seja recepcionado por nosso guia e inicie seu passeio.', 'Visite o Parque Memorial da Paz de Hiroshima. Veja o Salão de Promoção Industrial da Prefeitura de Hiroshima, hoje conhecido como Cúpula da Bomba Atômica, que foi um dos poucos prédios que sobreviveram ao bombardeio atômico de Hiroshima em 6 de agosto de 1945. Hoje, o prédio serve como um memorial às vítimas do bombardeio e como um símbolo da paz internacional. Visite o Museu Memorial da Paz de Hiroshima no local e saiba mais sobre o bombardeio. Veja artefatos comoventes da época da explosão atômica em exibição, bem como várias exposições educacionais.', 'O tour tem em torno de 8 horas de duração, e o pernoite será em Kyoto para onde retornaremos.'] },
            { id: 11, title: 'Kyoto para Tóquio - Sugestão de ir para Nara.', items: ['Ao invés de retornar para Kyoto, passei mais um dia na Cidade E conheça Nara. Nara é a capital da prefeitura japonesa de Nara, na região central de Honshu. A cidade tem templos e artes que remetem ao século 8, quando era a capital do Japão. Animais rugem no parque Nara, local do templo Tōdai-ji. Daibutsu, o Buda em bronze de 15 m de Tōdai-ji, é exposto em um grande salão de madeira. No lado leste do parque está o santuário Shinto Kasuga Taisha, que data de 768 D.C. com mais de 3.000 lanternas.'] },
            { id: 12, title: 'Kyoto para Tóquio', items: ['Dia Vá de trem Bala para Tokyo. Dia  livre na Cidade após check in no Hotel.'] },
            { id: 13, title: 'Tóquio', items: ['Sugestão opcional à acrescentar: Conhecer a Disney Tokyo. Passei um dia na atração que tem 7 modalidades únicas que não há em outras unidades da Disney.'] },
            { id: 14, title: 'Hakone', items: ['Hakone, situada no Parque Nacional Fuji-Hakone-Izu, a oeste de Tóquio, no Japão, é uma cidade montanhosa conhecida por seus resorts de águas termais (onsen) e pelas vistas do emblemático vulcão Monte Fuji. Nela, também fica o Hakone Jinja, um santuário xintoísta com um portão "torii" vermelho com vista para o lago Ashi.', 'Ao fim do passeio retorne para Tokyo.'] },
            { id: 15, title: 'Hakone', items: ['Dia livre para desfrutar a Cidade.'] },
            { id: 16, title: 'Ida para Seul', items: ['Aéreo e Traslado para o Hotel, sendo recepcionado por nossa equipe.'] },
            { id: 17, title: 'Seul', items: ['Passaremos o segundo dia conhecendo a capital sul-coreana. Depois do café da manhã no hotel, realizaremos uma visita guiada de cinco horas por Seul para conhecer seus lugares mais representativos. Exploraremos o complexo do Palácio de Changdeokgung, declarado Patrimônio da Humanidade.', 'Em seguida, passearemos pelo bairro de Bukchon Hanok, uma histórica área onde são famosas suas pequenas casas tradicionais. Posteriormente, caminharemos até o Memorial da Guerra, um impressionante museu que ilustra os conflitos vividos na Coreia ao longo da história.', 'Depois, faremos uma parada para comer em um dos restaurantes da região. Depois de repor as energias, continuaremos até o distrito de Gangnam, um dos lugares mais movimentados de Seul. Lá, passearemos por seus numerosos centros comerciais.', 'Por último, terminaremos o tour em Lotte World Tower, um imponente e moderno arranha-céu de 550 metros de altura. É um dos edifícios mais altos de Seul! Você terá tempo livre para subir por conta própria ou dar um passeio pelo seu parque.', 'O tour tem em torno de 8 horas de duração'] },
            { id: 18, title: 'Seul', items: ['DMZ, a zona desmilitarizada na região da fronteira com a Coreia do Norte – considerada uma das mais fortificadas em todo o mundo. Na região estão o Parque da Paz, Terceiro Túnel de Infiltração, construído pelos norte coreanos com a intuito de invadir o país vizinho, o Observatório Dora, de onde, com binóculos, é possível observar o território da Coreia do Norte, e a Ponte da Liberdade - ou Ponte do Não Retorno -, até 1968 utilizada para troca de prisioneiros. Na sequência, parada no observatório da Torre Namsan Seoul, ponto mais alto na cidade.', 'O tour tem em torno de 8 horas de duração'] },
            { id: 19, title: 'Seul', items: ['Começaremos o dia visitando o Palácio de Gyeongbokgung. Você sabia que conta com 600 anos de história? Também conhecido como o Palácio do Norte por sua localização, esse complexo é uma das cinco fortalezas da capital sul-coreana.', 'Depois, deixaremos Seul para trás para ir rumo à cidade de Suwon. Quando chegarmos, nos dirigiremos a suas impressionantes muralhas, que contam com 6 quilômetros de extensão. Em seguida, visitaremos o Samsung Innovation Museum para entender como evoluiu a tecnologia dessa famosa marca. Por último, entraremos no Palácio Hwaseong Haeng Ung.', 'Almoçaremos pela região e, depois de recarregar as energias, continuaremos nosso caminho até Jeonju, onde você se surpreenderá com a tradicional Hanok Village'] },
            { id: 20, title: 'Jeonju - Haeinsa - Daegu - Busan', items: ['No quarto dia, nos despediremos de Jeonju e iremos rumo a Haeinsa, um importante templo budista situado na ladeira da montanha Gayasan e rodeado de bosques e riachos. Este lugar se transformou em um dos melhores do país para praticar caminhada.', 'Nossa seguinte parada será em Daegu, a quarta maior cidade da Coreia do Sul e uma das mais povoadas, onde conheceremos o mercado de medicinas tradicionais. Lá, faremos uma parada para almoçar e repor as forças.', 'Por último, viajaremos até Busan para jantar e passar a noite.'] },
            { id: 21, title: 'Busan - Haedong Yonggung - Seul', items: ['Após tomar café da manhã, visitaremos o templo budista de Haedong Yonggang Sa, que se encontra situado em frente ao mar. As vistas são realmente espetaculares! Você sabia que esse edifício foi reconstruído em 1970 depois da invasão japonesa de Manchúria em 1932.', 'Continuaremos a visita explorando os pontos mais importantes de Busan, uma cidade litorânea conhecida por seu contraste de arranha-céus, praias, montanhas e templos. Tem muito a oferecer! Entre esses lugares, conheceremos o maior mercado de peixes do país e contemplaremos o Memorial das Nações Unidas.', 'Depois de lhe dar tempo livre para comer, regressaremos a Seul de trem de alta velocidade. Quando chegarmos à capital, lhe levaremos ao hotel para que você possa descansar.'] },
            { id: 22, title: 'Ida para Hanoi - Vietnã', items: ['Transfer para o Aeroporto de Seul para voo até o Vietnã.', 'Inclui aéreo para o Vietnã.'] },
        ],
        incluso: ['Transfer', 'hospedagem com Hotel + Café da manhã. 3 e 4 estrelas.', 'Tickets para os passeios e transportes', 'Guia em português'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },

    {
        id: 104,
        urlName: 'india',
        url: '/pacote/india',
        nome: 'Índia',
        pais: 'Triângulo Dourado',
        img: 'india.webp',
        description: '11 dias',
        valor: '24.500',
        dias: [
            { id: 1, title: 'Chegada em Delhi', items: ['Chegando no aeroporto o transfer irá te levar para o hotel. Restante do dia livre. Hospedagem.'] },
            { id: 2, title: 'Delhi', items: ['Após o café da manhã. Vamos começar pelos templos de impressionam pela arquitetura de Chattarpur, a maioria deles de mármore branco, indo para Qutab Minar (Torre da Vitória), construído no século XII, atravessaremos os mercados de Nova Delhi, para a tumba de Humayun, construída a mando de sua mulher, com sua enorme cúpula de mármore branco marca o nascimento da arquitetura mogol. Conheceremos oTemplo Sikh, da região do Punjab, o Templo hinduista Birla, dedicado a Lakshmi deusa da prosperidade e riqueza, e a seu esposo Vishnu, construído com mármore branco e pedra arenisca de cor vermelha um dos templos mais populares de Delhi; inaugurado por Mahatma Gandhi, que impôs a condição de que fossem admitidas pessoas de todas as castas, incluída a dos intocáveis. A placa na porta lembra que pessoas de todas as religiões e de todas as classes sociais são bem-vindos. Em direção a Velha Delhi, observando seus os edifícios, ruas, gentes e costumes, que há entre a Nova e a Velha Delhi. Passando pelo Forte Vermelho de caminho à Jama Masjid, a maior mesquita da Índia, onde tem uma magnífica vista panorâmica do Velho Delhi. Almoço. Agora vamos para Nova Delhi conhecer a famosa Porta da Índia e conhecer a zona dos edifícios governamentais, o Palácio Presidencial e o Parlamento. Vamos de transfer até o Kingdom of the Dreams, um espetacular mundo de imaginação, oferece a mistura da cultura da Índia, seu patrimônio, sua arte, sua gastronomia e Bollywood, com entrada ao Cultural Gully (incluso), passeio que possibilita conhecer réplicas de diferentes edifícios famosos de Índia como a stupa de Sanchi, os Templos de Khajuraho, uma típica casa barco de Kerala, entre outras. Jantar típico indiano no restaurante West Bengal. Retorno para hotel.'] },
            { id: 3, title: 'Dia Delhi - Jaipur', items: ['Após o café da manhã. Vamos para Jaipur. Almoço, dentro do antigo palácio (Samode Palace). Jaipur é conhecida como a Cidade rosa, por cauda da cor de suas casas, a capital do estado de Rajasthan, antes chamado de Rajputana, terra dos rajputs, construída no século XVIII pelo marajá Jai Singh II, de onde recebe seu nome, a cidade de Jai. A terminação Pur indica que a cidade é de origem hindu, ao contrário de Cad, que indica cidade árabe. Pela tarde, saída para conhecer um templo Hindu, onde poderemos apreciar a cerimônia AARTI, que em sânscrito significa dissipador da escuridão.'] },
            { id: 4, title: 'Jaipur', items: ['Após o café da manhã. Vamos para o Forte Amber, passeio em elefante, visita dos aposentos e vestíbulos do palácio. O Palácio de Amber é decorada com muitos florais, com o Deus Elefante Ganesh sobre a porta, e suas grandes janelas de grade de pedra, de onde as mulheres podiam ver sem ser vistas. O interior do palácio também é muito belo, com seus muros decorados com pequenos espelhos incrustados, motivo deste ser chamado de Sheesh Mahal, o Palácio dos Espelhos. De tarde visita ao observatório de Jantar Mantar, construído em 1700 e que até hoje parece futurista, do Palácio da Cidade ou Palácio do Marajá, do Hawa Mahal ou (Palácio dos Ventos), com sua lindíssima fachada em rosa e branco e com seus numerosos mirantes repletos com quase mil janelas e janelas de rótula para poder observar o exterior sem ser visto. Pela noite assistiremos a um Show de Luz e Som (realizado em inglês) na cidade de Amer, durante o qual desfrutaremos das lendas, folclore e música tradicionais de Índia. Regresso ao hotel, Jantar e Acomodação - Este show de Luz e Som na cidade de Amber ficará sujeito a cancelamento em função das condições climatológicas, pois o mesmo é celebrado ao ar livre. Em caso de anulação do mesmo, se oferecerá aos clientes bem realizar uma visita à parte velha da cidade bem assistir a um cinema em Jaipur para ver um filme indiano (Bollywod).'] },
            { id: 5, title: 'Jaipur - Agra', items: ['Café da manhã. Saída até Agra, visitando no caminho Fatehpur Sikri, a desértica cidade de areia vermelha construída pelo Imperador Akbar como sua capital e Palácio no século XVI. Também visitaremos a maior porta do mundo, a Bulund Darwaza. Chegada a Agra, no estado de Uttar Pradesh, fundada em 1505 pelo sultão de Deli, Sikander Lodi, sobre uma antiga cidade de origem hindu. Check-in no hotel (dependendo do horário o check-in poderá ser feito depois da visita). Mais tarde, visita ao mais famoso monumento arquitetônico do mundo, o Taj Mahal, construído entre 1631 e 1654, às margens do rio Yamuna, pelo imperador muçulmano Shah Jahan, em homenagem a sua esposa favorita, Arjumand Bano Begum, mais conhecida como Mumtaz Mahal, que morreu dando a luz a seu 14º filho. O Taj Mahal é considerado o mais belo exemplo da arquitetura mongola, estilo que combina elementos da arquitetura Islâmica, Persa, Índia e inclusive Turca. O monumento tem especial notoriedade pelo caráter romântico de sua inspiração. Ainda que o mausoléu coberto pela cúpula de mármore branco seja a parte mais conhecida, o Taj Mahal é um conjunto de edifícios integrados. Jantar e Acomodação.'] },
            { id: 6, title: 'Delhi - Varanasi', items: ['Após o café da manhã. No horário pré-determinado o transfer irá te levar o aeroporto doméstico de Nova Delhi com destino a Varanasi. Chegando a Varanasi o transfer irá te levar para o hotel. De tarde, vamos visitar a Universidade e os templos Durga, Tulsi Manas e Bharat Mata. Passeio pelas estreitas travessias e bazares cheios de lojas onde se pode comprar as mais famosas sedas Banaras do mundo. A imortal antiguidade de Varanasi estampa um rico e vibrante passado. Jantar e hospedagem.'] },
            { id: 7, title: 'Varanasi - Delhi', items: ['Após o café da manhã. Vamos de carreteira para Daswamedh Ghat e passeio de barco pelo sagrado rio Ganges. No horário pré-determinado o transfer irá te levar para o hotel do aeroporto para tomar o vôo com destino a Nova Delhi. Chegando em Nova Delhi o transfer irá te levar para o hotel. Hospedagem.'] },
            { id: 8, title: 'Ida para Kathmandu', items: ['Após o café da manhã. No horário pré-determinado o transfer irá te levar para o aeroporto para embarcar em seu vôo para Kathmandu.', 'Terá a tarde livre para conhecer a Cidade.'] },
            { id: 9, title: 'Kathmandu', items: ['Após o café da manhã. Vamos passar bevemente na cidade de Swayambunath, conhecer à Estupa de Swayambunath, e depois na cidade de Patan, para conhcer a Praça Durbar. Almoço. Voltando à Cidade de Katmandú. Conhecer a Praça Durbar. Acomodação.'] },
            { id: 10, title: 'Kathmandu', items: ['Dia livre.'] },
            { id: 11, title: 'Retorno para o Brasil', items: ['Após o café da manhã. No horário pré-determinado o transfer irá te levar para o aeroporto para embarcar em seu vôo de retorno.'] },
        ],
        incluso: ['Hospedagem com Hotel + Café da manhã.', 'Tickets para os passeios e transportes', 'Guia em português'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },

    {
        id: 105,
        urlName: 'ira',
        url: '/pacote/ira',
        nome: 'Irã',
        pais: 'Teerâ + Yazd + Isfahan',
        img: 'ira.webp',
        description: '10 dias',
        valor: '18.500',
        dias: [
            { id: 1, title: 'Chegada em Teerã', items: ['Chegada em Teerã pela manhã, traslado para o hotel e check-in. Após o café da manhã, passeio de dia inteiro pela cidade de Teerã para visitar: Complexo do Palácio Golestan - Patrimônio Mundial (2 Palácios), Grande Bazar de Teerã - Museu Nacional (Pré-Islâmico) e Museu do Tapete. Irã Mall (O Maior Shopping do Mundo, não apenas para compras, há também muitos lugares turísticos) e lago Chitgar.'] },
            { id: 2, title: 'Teerã', items: ['Após o café da manhã no norte da cidade, visite o complexo Sa’ad Abad (palácio verde e branco e Museu de Arte Farshchian), em seguida, Museu do Tempo (apenas por fora devido à construção), Jardim Bagh Ferdos e Museu do Cinema, Santuário de Imam Zade Saleh (neto do sétimo imã dos muçulmanos xiitas) e o tradicional Bazar Tajrish, Visite o Jardim de Arte Iraniana e jante lá.'] },
            { id: 3, title: 'Ida para Yazd', items: ['No caminho para Kashan, Visite o Santuário do Imam Khomeini (O Primeiro líder da revolução iraniana), visite Seyede Massuma na cidade de Qom, visite o Jardim Fin em Kashan (Patrimônio Mundial), depois faça check-in no hotel.'] },
            { id: 4, title: 'Kashan e Partida para Isfahan', items: ['Visita às Torre do Silêncio, local onde os zoroastrianos deixavam seus cadáveres para excarnação, Templo do Fogo, Praça Amir Chakhmakh, o antigo distrito de Fahadan e o Jardim Dowlat Abdad, Patrimônio da Humanidade pela UNESCO e um dos melhores exemplos de jardins iranianos.'] },
            { id: 5, title: 'Partida para Isfahan', items: ['Pela manhã, após o café da manhã, Visite o Palácio Chehelsotoun, um pavilhão no meio de um jardim. No Irã, Isfahan é conhecida como "nesf-e jahan". Significa metade do mundo, deixando claro o quão importante esta cidade é para a Pérsia e o mundo islâmico. Aqui estão alguns dos melhores exemplos de arquitetura islâmica. Patrimônio Mundial da UNESCO Shah Abbas Grand Square, cercada por edifícios da era Safávida, é a segunda maior praça do mundo. A Mesquita Shah fica no lado sul. No lado oeste está o Palácio Ali Qapu. A Mesquita Sheikh Lotf Allah fica no lado leste e, no lado norte, o portão Keisaria se abre para o Grande Bazar de Isfahan. Em seguida, caminhe pela Ponte Khajou.'] },
            { id: 6, title: 'Isfahan', items: ['Rua Jolfa, no bairro armênio, onde está localizada a Catedral Vank. Perto da Catedral, o Museu da Música abriga uma excelente coleção de instrumentos tradicionais persas e regularmente realiza apresentações ao vivo de músicos populares renomados. Tempo livre em Isfahan.'] },
            { id: 7, title: 'Partida para Yazd', items: ['Visita à Torre do Silêncio, onde os zoroastristas deixavam seus corpos para excarnação, o Templo do Fogo, Praça Amir Chakhmakh, o antigo distrito de Fahadan e o Jardim Dowlat Abdad, Patrimônio Mundial da UNESCO e um dos melhores exemplos de jardins iranianos.'] },
            { id: 8, title: 'Partida para Shiraz ', items: ['No caminho, visite o Cipreste de Abarkuh, também chamado de Sarv Zoroastriano. Protegido pela Organização do Patrimônio Cultural do Irã, tem 25 metros de altura e estima-se que tenha mais de 4.000 anos, tornando-o provavelmente o segundo ser vivo mais antigo da Ásia. Próxima parada, Pasárgada (Patrimônio Mundial da UNESCO), capital do Império Aquemênida sob Ciro, o Grande, e local de seu túmulo. Visita a Persépolis (Patrimônio Mundial da UNESCO). A capital cerimonial do Império Aquemênida, um dos monumentos mais importantes do Irã. Visita a Naghshe Rostam, uma antiga necrópole localizada a cerca de 12 km a noroeste de Persépolis.'] },
            { id: 9, title: 'Shiraz ', items: ['À tarde, um passeio pela cidade incluindo a Mesquita Nasir, também conhecida como Mesquita Rosa. Em seguida, Mesquita Vakil, o túmulo do grande poeta iraniano Hafezieh, no meio de um belo jardim, e o Jardim Eram, Patrimônio Mundial da UNESCO.'] },
            { id: 10, title: 'Retorno para SP', items: ['Café da manhã', 'Traslado para o Aeroporto.'] },
        ],
        incluso: ['Transfer', 'hospedagem com Hotel + Café da manhã.', 'Tickets para transportes', 'Guia em português'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },
  
    {
        id: 106,
        urlName: 'tunisia',
        url: '/pacote/tunisia',
        nome: 'Tunísia',
        pais: 'África',
        img: 'tunisia.webp',
        description: '9 dias',
        valor: '18.500',
        dias: [
            { id: 1, title: 'Partida de São Paulo', items: ['Partida de Guarulhos.'] },
            { id: 2, title: 'Tunis-Carthage Aeroporto / Gammarth', items: ['Recepção pelo nosso guia e traslado o hotel.'] },
            { id: 3, title: 'Bardo / Tunis /Carthage / Sidi Bou Said', items: ['Café da manhã no hotel e saída para Visitar a Medina de Túnis, centro histórico da cidade classificado pela UNESCO com suas ruas estreitas e diferentes departamentos artesanais. um ótimo lugar para combinar História, Cultura e Compras. Pausa para almoço grátis em restaurante local.', 'Visita da Lendária Cidade Romana de Cartago e dos Antigos Banhos Romanos O sítio arqueológico de Cartago é um sítio disperso na atual cidade de Cartago (Tunísia) e classificado como Património Mundial da UNESCO desde 1979.', 'Visite a cidade de Sidi Bou Said As casas de Sidi Bou Saïd, que combinam arquitectura árabe e andaluza e são de uma brancura brilhante e portas azuis, estão espalhadas por vielas sinuosas e aleatórias. Local turístico nas cores do Mar Mediterrâneo, classificado desde 1915, o local é apelidado de “pequeno paraíso branco e azul”.'] },
            { id: 4, title: 'Tunis/ Kairouan / Tozeur', items: ['Café da manhã no hotel e saída em direção a Kairouan. Uma vez lá, viaje de volta no tempo para as Bacias Aghlabitas do século IX, que podem ser vistas do topo do Edifício de Informações Turísticas. Considere esta primeira visita como uma introdução à fundação de Kairouan e à sua era gloriosa.', 'Em seguida, prossiga para a Grande Mesquita Okba, do século VII, a mais antiga de todo o Norte da África. Aprecie a poderosa e bela expressão de fé inerente ao edifício do século IX. Encontre a seguir Sidi Sahbi, nas proximidades, e explore o edifício com seu minarete e pátios deslumbrantes, revestidos com azulejos verdes e azuis e estuque de gesso branco. ', 'À entrada da Medina, desfrute de um passeio guiado pela rua principal desta mística lugar. Aproveite o tempo livre disponível para embarcar em uma busca pela descoberta individual e pelas compras nas proximidades.', 'Continue em direção a Tozeur.'] },
            { id: 5, title: 'Tozeur /Chebika / Tamerza / Mides / Ong Ejjemal / Tozeur', items: ['Café da manhã no hotel e saída do hotel em confortável veículo 4x4 Cubra os principais pontos turísticos ao redor de Tozeur durante este dia inteiro.', 'Descubra a beleza natural do sul da Tunísia com paradas nos cânions das cachoeiras Tamerza e Chebika, além do antigo oásis de Mides. Obtenha acesso off-road ao Deserto do Saara em um veículo 4x4.', 'Visite locais de filmagem de Star Wars nas profundezas das dunas, incluindo Ong Jemel.', 'Aproveite o pôr do sol. Retorne ao seu hotel'] },
            { id: 6, title: 'Tozeur / Matmata / Djerba', items: ['Café da manhã no hotel e saída do hotel em confortável veículo 4x4 Matmata é mais conhecida por suas cavernas subterrâneas ou estruturas trogloditas, que abrigam parte da população berbere local.', 'Visite uma casa e entre em contato com os moradores locais. Partida para Djerba classificada como Património Mundial da UNESCO Pegando a balsa para Djerba Almoço gratis e Visita de Houmt Souk, Sinagoga de Gallala e Djerba Hood'] },
            { id: 7, title: 'Djerba/ El Jem / Sousse', items: ['Café da manhã no hotel e saída para Sousse. Chegue a El Jem e visite o anfiteatro de El Jem classificado como Património Mundial da UNESCO As impressionantes ruínas do maior coliseu do Norte de África, um enorme anfiteatro com capacidade para 35.000 espectadores, encontram-se na pequena aldeia de El Jem. Este monumento do século III ilustra a grandeza e a extensão da Roma Imperial.'] },
            { id: 8, title: 'Sousse / Tunis', items: ['Café da manhã no hotel e visita à Antiga Medina de Sousse e ao Porto de Kantaoui. Saída para Tunes. Chegue a Túnis com almoço grátis Visita ao Museu do Bardo, que alberga a maior Colecção de Museu Romano do mundo classificada como Património Mundial da UNESCO.'] },
            { id: 9, title: 'Retorno para São Paulo', items: ['3 horas antes do seu voo um Traslado para o aeroporto.', 'Retorno para SP'] },
        ],
        incluso: ['Inclui hospedagem com Hotel + Café da manhã', ' gorjetas para Guias e Motoristas', 'Guia em português'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras'],
    },

    // Africa

    {
        id: 201,
        urlName: 'egito',
        url: '/pacote/egito',
        nome: 'Egito',
        pais: 'Com Nilo',
        img: 'egito.webp',
        description: '9 dias',
        valor: '17.500',
        dias: [
            { id: 1, title: 'Partida de São Paulo', items: ['Partida de Guarulhos para o Cairo.'] },
            { id: 2, title: 'Desembarque no Cairo', items: ['Ao chegar no Cairo nossa equipe irá receber você na imigração, onde cuidaremos de tudo. Em seguida levaremos você até o Hotel com vista para às pirâmides para que possa descansar da viagem.'] },
            { id: 3, title: 'Pirâmides e Sakara', items: ['Começamos com um café da manhã no hotel com vista das Pirâmides. Um sonho acordado para te animar logo cedo.', 'Logo depois iniciaremos a visita a uma das sete maravilhas do mundo, as Pirâmides de Giza: Keops, Kefren e Micerinos. Visita à Esﬁnge e ao Templo do Vale, construído pelo rei Kefren.', 'Visita de Sakara(a pirâmide mais velha do mundo de Zoser) ,a Mastaba de Kagemni e a Pirâmide de Titi. Memphis.', 'Depois voltaremos para o hotel no fim da tarde. Estar de frente com todos esses monumentos de mais de 5 mil anos, mostra como é misteriosa a nossa história. Nosso guia especialista em Egito, falando em português para que você não perca nenhum detalhe.'] },
            { id: 4, title: 'Do Cairo para Aswan - Embarque no Cruzeiro.', items: ['Após o Café da manhã iremos bem cedo ao aeroporto do Cairo para embarque no voo doméstico, que já está incluso no pacote, com destino à Aswan.', 'Nossa equipe em Aswan irá receber você para iniciarmos a visita à barragem de Aswan, construída em 1960 para proteger o Egito das inundações do Rio Nilo.', 'Visita ao Templo de Philae, dedicado à Deusa Ísis.', 'Em seguida, vamos ao Barco do cruzeiro para embarque com Jantar a bordo incluso. Ele seguirá a viagem no dia seguinte.'] },
            { id: 5, title: 'Templo de Sobek em Kom Ombo (Tour de Abu Simbel opcional)', items: ['Café da manhã a bordo. O cruzeiro segue para Kom Ombo.', 'Visita ao Templo de Sobek, dedicado ao Deus Falcão Hórus e Sobek, divindade local com cabeça de crocodilo. O cruzeiro segue para Edfu.', 'Dentro do Cruzeiro, Café, almoço e jantar estão inclusos.', 'O Tour de Abu Simbel é opcional, devendo sair bem cedinho para a visita. Ele tem um custo não incluso no pacote de U$120,00 dólares.'] },
            { id: 6, title: 'Templo de Edfu-Esna-Luxor', items: ['Café da manhã a bordo. O cruzeiro segue para Edfu.', 'Faremos um passeio de carruagem até o Templo de Hórus, o mais bem preservado do Egito.', 'Na tarde Visita ao Templo de Luxor, construído durante o Império Novo. Visita ao templo de Karnak, centro religioso mais inﬂuente naquela época dedicado ao culto do Deus Amon e outros Deuses.', 'Retorno ao Cruzeiro.'] },
            { id: 7, title: 'Vale dos Reis e LUXOR/ Retorno ao Cairo', items: ['VOO DE BALÃO OPCIONAL - Caso deseje iniciar seu dia bem cedinho, para ver o sol no Vale dos Reis em um passeio incrível. Ele tem um custo extra de U$120,00 dólares. Uma experiência única.', 'Retornando ao Cruzeiro, Café da manhã a bordo, desembarque do cruzeiro Visita ao Vale dos Reis, local onde foram enterrados, nas montanhas, Reis e algumas Rainhas, Príncipes, Nobres e animais.', 'Depois uma visita ao templo original da Rainha Hatshepsut, o de maior valor e prestígio entre os monumentos construídos no vale. Visita aos Colossos de Memnon.', 'No fim da tarde iremos de Trasfer ao aeroporto de Luxor para retorno ao Cairo e descanso no Hotel.'] },
            { id: 8, title: 'Cairo Cultural', items: ['Após o café da manhã, Vamos iniciar o dia com a visita à Mesquita mais famosa do Egito do Rei Mohamed Ali (Mesquita de Alabastro) que está localizada na Citadela de Saladino, o que poucos sabem.', 'Depois iremos até a Gruta, onde Jesus, Maria e José se esconderam de Herodes. A sagrada família ficou no Egito por aproximadamente 3 anos. Na gruta é possível ver o berço de pedra onde ficava Jesus Cristo. Acima, foi construída uma igreja.', 'Após, uma Visita ao Museu Egípcio em Tahrir , que contém a maior coleção do Império Faraônico, além de monumentos do antigo Egito e a sala do tesouro do jovem Rei Tutankhamon.', 'Mais tarde uma Visita ao Grande Bazar do Cairo.'] },
            { id: 9, title: 'Retorno para São Paulo', items: ['3 horas antes do seu voo um Traslado para o aeroporto', 'Retorno para SP.'] },
        ],
        incluso: ['Hospedagem com Hotel + Café da manhã', 'Inclui todos os ingressos e transfer para os passeios citados ( Exceto Abu Simbel e Voo de Balão U$120 dólares cada)', 'Guia em português', 'Gorjetas para Guias e Motoristas'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras'],
    },

    {
        id: 202,
        urlName: 'marrocos',
        url: '/pacote/marrocos',
        nome: 'Marrocos',
        pais: 'Casablanca + Fez + Marrakech',
        img: 'marrocos.webp',
        description: '9 dias',
        valor: '15.800',
        dias: [
            { id: 1, title: 'Casablanca', items: ['Chegada em Casablanca.', 'Transfer para o hotel.'] },
            { id: 2, title: 'Casablanca - Fez', items: ['Após o café da manhã, visitaremos o Mercado Central, distrito de Habous, o Palácio Real, Praça Mohamed V, área residencial de Anfa, o exterior da Mesquita de Hassan II. Seguiremos para Meknes para conhecer a capital (Ismaïana), onde encontraremos as maiores muralhas de Marrocos. Iremos almoçar no caminho.', 'Chegando a Fez ao anoitecer e indo para o Hotel.'] },
            { id: 3, title: 'Fez', items: ['Após o café da manhã, faremos uma visita de duração de um dia à capital Espiritual, conheceremos a Medina, a fonte Nejjarine, o Mausoléu de Moulay Idriss e a Mesquita Karaouine. Iremos almoçar no hotel. À tarde, visitaremos os souks e a nova Medina Fez Jdid.'] },
            { id: 4, title: 'Fez - Rabat  Marrakech', items: ['Após o café da manhã, partiremos para Rabat. Visitaremos o Palácio Real Mechouar, o Jardim e a Kasbah Oudaya, o Mausoléu de Mohamed e a Torre da Mesquita.', 'Chegando a Marrakech, jantar e hospedagem.'] },
            { id: 5, title: 'Marrakech', items: ['Após o café da manhã, faremos uma visita de duração de um dia à cidade, a segunda mais antiga do Império (A Pérola do Sul). Visitaremos a parte histórica, o jardim Menara, o Túmulo de Saadian, o Palácio Baía, a Koutoubia e o Museu Dar Si Said. Almoçaremos no hotel. À tarde, conheceremos os souks e os bairros dos artesãos. Jantaremos no famoso restaurante - Show Dar Soukkar (opcional).', 'Hospedagem'] },
            { id: 6, title: 'Marrakech - Zagora', items: ['Após o café da manhã, partiremos para Ait Benhaddou, conheceremos a famosa Kasbah (Patrimônio da Humanidade da UNESCO). Almoçaremos no caminho. Seguiremos para Zagora pelo Vale de Draa.'] },
            { id: 7, title: 'Zagora - N Kob - Tazzarine - Erfoud', items: ['Após o café da manhã, seguiremos para Tamgroutte, onde visitaremos a biblioteca corânica. Partiremos até Erfoud atravessando N Kob e Tansikht, com vista sobre o Sahara e as aldeias berberes. Almoçaremos no caminho. Em Tazzarine, antes de chegar à Erfoud, passaremos por Alnif e Rissani.', 'Chegando, jantar e hospedagem.', 'IMPORTANTE: Passeio opcional em 4x4 à Merzouga para assistir ao anoitecer nas dunas do deserto com jantar e hospedagem em uma típica haime no deserto.'] },
            { id: 8, title: 'Erfoud - Tinghir - Ouarzazate', items: ['Após o café da manhã, partiremos para Tinghir, visitaremos os magníficos canhões de Todra com pedras de mais de 250 metros. Almoçaremos no caminho. À tarde, seguiremos para Ouarzazate, pela Rota das Mil Kasbahs.', 'isita ao deserto do Saara e pernoite opcional.'] },
            { id: 9, title: 'Ouarzazate - Marrakech - Retorno para SP', items: ['Após o café da manhã, passeio com vista panorâmica da cidade de Ouarzazate. Seguiremos para Marrakech.', 'traslado ao aeroporto e retorno à SP.'] },
        ],
        incluso: ['hospedagem com Hotel + Café da manhã (4 estrelas)', 'Tickets para as atrações.', 'Guia em português'],
        naoIncluso: ['Aéreos','Não inclui aéreo de São Paulo para o Marrocos - ida e volta.', 'Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },

    {
        id: 203,
        urlName: 'tanzania',
        url: '/pacote/tanzania',
        nome: 'Tanzânia',
        pais: 'Safari',
        img: 'tanzania.webp',
        description: '9 dias',
        valor: '17.500',
        dias: [
            { id: 1, title: 'Chegada na Tanzânia', items: ['Chegada em Zanzibar com transfer para o hotel.', 'Pernoite em Zanzibar.'] },
            { id: 2, title: 'Tour por Zanzibar + The Rock (opcional)', items: ['Pela manhã, tour em Stone Town que é o centro de Zanzibar. O almoço é opcional para quem quiser no icônico The Rock.'] },
            { id: 3, title: 'Safari no Serengeti', items: ['Saída pela manhã em direção ao Serengeti e início do safari game.','Pernoite no Serengeti.'] },
            { id: 4, title: 'Safari no Serengueti', items: ['Dia épico! Safari no Serengueti. Tá preparada? Há cerca de 70 espécies de grandes mamíferos e 500 de aves na região, e esta grande diversidade é função de vários habitats como florestas, pântanos, inselbergues, savanas e bosques. Aqui você terá chance de ver os big 4: leão, o elefante, o búfalo e o leopardo. Pernoite no Serengeti.'] },
            { id: 5, title: 'Cratera Ngorongoro + transfer para Arusha', items: ['Se o Serengueti não basta pra você, não tem problema – tem safari na cratera Ngorongoro, a maior cratera intacta do mundo. No fim, transfer de volta para Arusha. Pernoite em Arusha.'] },
            { id: 6, title: 'Kilimanjaro', items: ['Trekking no Kilimanjaro (Lemosho route), monte localizado no norte da Tanzânia junto à fronteira com o Quénia. É o ponto mais alto da África.'] },
            { id: 7, title: 'Retorno para Zanzibar', items: ['Retorno para Zanzibar e dia livre.'] },
            { id: 8, title: 'Retorno para Zanzibar', items: ['Desfrute da deslumbrante  ilha de Zanzibar, conhecida por suas praias de areia branca, águas cristalinas e rica história cultural.'] },
            { id: 9, title: 'Retorno para São Paulo', items: ['Traslado para o aeroporto e retorno para São Paulo'] },
        ],
        incluso: ['Valor com tour guiado!', 'Inclui hospedagem com Hotel + Café da manhã', 'voos entre Dentro do continente Africano'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras'],
    },

    // Europa

    {
        id: 301,
        urlName: 'alemanha-profunda',
        url: '/pacote/alemanha-profunda',
        nome: 'Alemanha',
        pais: 'Berlim à Frankfurt',
        img: 'alemanha.webp',
        description: '11 dias',
        valor: '22.800',
        dias: [
            { id: 1, title: 'Chegada em Berlim', items: ['Recepção no aeroporto.', 'Transfer para o Hotel.'] },
            { id: 2, title: 'Berlim', items: ['Explore os marcos históricos como o Portão de Brandemburgo e o Muro de Berlim.', 'Visite museus como o Museu Pergamon e o Museu Judaico. Combine com um Citytour para ver o melhor da Cidade.'] },
            { id: 3, title: 'Berlim', items: ['Dia livre em Berlim para desfrutar a Cidade.'] },
            { id: 4, title: 'Dresden', items: ['Sairemos bem cedo para Dresden e exploraremos sua arquitetura barroca, incluindo a Ópera Semper e a Igreja de Nossa Senhora.  Visitando os museus na área do Palácio Real', 'Pernoite na Cidade.'] },
            { id: 5, title: 'Ida para Munique', items: ['Partida para Munique.', 'Dia livre.'] },
            { id: 6, title: 'Munique', items: ['Visitaremos a Marienplatz e o famoso Glockenspiel. Vamos também visitar o Museu da BMW. Visita  ao Palácio de Nymphenburg e seus jardins. E um tour pela cervejaria Hofbräuhaus.'] },
            { id: 7, title: 'Nuremberg', items: ['Partiremos cedo e começaremos o dia explorando o Castelo de Nuremberg, uma imponente fortaleza que oferece vistas panorâmicas da cidade. Visite também a Torre Sinwell, a prisão do castelo, e o Poço do Diabo.', 'Faremos um passeio pelo centro histórico de Nuremberg, conhecido como Altstadt. Explore a Hauptmarkt, a praça central, onde você pode admirar a bela Fonte da Beleza (Schöner Brunnen) e experimentar as famosas salsichas de Nuremberg em uma das barracas de comida.'] },
            { id: 8, title: 'Kyoto', items: ['Visitaremos o Campo de Concentração de Nuremberg (Memorial Dokumentationszentrum Reichsparteitagsgelände) para aprender sobre o período sombrio da história alemã durante o regime nazista. O memorial oferece exposições informativas e emocionantes sobre os eventos que ocorreram aqui durante a Segunda Guerra Mundial.', 'No fim do dia partiremos para Frankfurt.'] },
            { id: 9, title: 'Frankfurt', items: ['Terminaremos nossa viagem em Frankfurt, explorando sua arquitetura moderna e sua agitada cena cultural. Visitaremos o Museu Städel e passearemos pelo bairro de Sachsenhausen para experimentar a culinária local.'] },
            { id: 10, title: 'Dia livre em Frankfurt', items: ['Dia livre em Frankfurt para desfrutar a Cidade.'] },
            { id: 11, title: 'Retorno para SP', items: ['Transfer para o aeroporto e retorno para São Paulo.'] },
        ],
        incluso: ['Transfer', 'hospedagem com Hotel + Café da manhã. 3 e 4 estrelas', 'Tickets para os passeios e transportes', 'Guia em português'],
        naoIncluso: ['voos internacionais', 'Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },
  
    // {
    //     id: 302,
    //     urlName: 'italia-profunda',
    //     url: '/pacote/italia-profunda',
    //     nome: 'Itália',
    //     pais: 'Roma',
    //     img: 'roma.webp',
    //     description: '10 dias',
    //     valor: '22.800',
    //     dias: [
    //         { id: 1, title: 'Chegada em Roma', items: ['Recepção no aeroporto.', 'Transfer para o Hotel.'] },
    //         { id: 2, title: 'Roma', items: ['Faremos uma visita panorâmica pela capital da Itália e uma das cidades mais antigas e influentes da civilização ocidental. O Coliseu, os fóruns romanos, as praças e fontes no centro, a grande vida da cidade.', 'Terminamos na praça São Pedro no Vaticano, um estado independente dentro da cidade de Roma e centro da Igreja Católica. Visitaremos a basílica e os museus.'] },
    //         { id: 3, title: 'Roma - Florença', items: ['Viagem para a capital da Toscana, FLORENÇA, na chegada, paramos para tirar uma foto no mirante de Michelangelo, onde podemos ver uma das mais belas vistas sobre a cidade.', 'Depois disso, incluímos uma visita com um guia local da capital toscana, uma das cidades mais visitadas do país, considerada o berço do Renascimento e alguns dos artistas e pensadores mais influentes da história, como Leonardo da Vinci, Michelangelo, Botticelli ou Rafael.', 'Os destaques incluem a catedral gótica (Duomo), a antiga ponte de pedra (Ponte Vecchio) com suas ourivesarias e a Piazza della Signoria – o coração político da cidade com a prefeitura em um edifício medieval.'] },
    //         { id: 4, title: 'Florença - Pisa - Cinque Terre - Gênova', items: ['Viagem rumo a PISA. Parada no estacionamento turístico. Incluído transporte em trem turístico até o recinto monumental Campo dos Milagres, onde está localizada a famosa Torre Inclinada.', 'Em seguida, passagem pela COSTA DA LIGÚRIA, que apresenta um dos recantos marítimos mais bonitos da Europa. A viagem segue para CINQUE TERRE, região declarada Patrimônio da Humanidade pela UNESCO, com cinco pequenas cidades praticamente ilhadas pela estrada e que sobrevivem da pesca e do turismo.', 'Dadas as dificuldades de acesso pela estrada chegaremos de trem, passagem incluída para VERNAZZA, que conserva seu aspecto de cidadela marinheira, destaca-se sua igreja de Santa Margarida construída em 1318, às margens do mar. Tempo para almoçar. Continuação em trem até LEVANTO, onde o ônibus estará esperando para seguir viagem até GÊNOVA. Seu centro traz recordações de que a cidade foi uma república próspera na Idade Média.'] },
    //         { id: 5, title: 'Gênova - Portofino - Milão', items: ['Viajaremos à próxima cidade de SANTA MARGHERITA LIGURE, onde embarcaremos rumo a PORTOFINO, um dos lugares mais exclusivos da Europa. Tempo livre. Após o almoço seguiremos para MILÃO, a movimentada cidade do norte, onde recomendamos uma visita à catedral. Nota: Às vezes, devido às condições climáticas e durante o inverno, o barco para Portofino não opera, nesses casos haverá mais tempo em Santa Margarita sendo possivel, se desejar, acessar Portofino por microônibus urbanos (serviço de qualidade deficiente e às vezes longas esperas).'] },
    //         { id: 6, title: 'Milão - Região de Los Lagos - Verona', items: ['Hoje conheceremos uma das regiões paisagísticas mais bonitas da Europa: a região dos lagos italiana: Viajaremos primeiramente para o LAGO D’ORTA, em ORTA SAN GIULIO encontraremos em uma cidade medieval que se conserva intacta. Faremos o passeio de trenzinho que nos deixará próximo do centro do povoado medieval e de barco à ILHA SAN GIULIO, pequena ilha do lago com mosteiro. Depois disso continuaremos rumo ao LAGO MAGGIORE, às suas margens pararemos em STRESA, a partir daqui poderemos conhecer opcionalmente, caso deseje, as Ilhas Borromeas, destacando-se Isola Bella com seus jardins e palácio (fechado no inverno). Tempo para almoçar. Após a hora do almoço iremos para VERONA.Nota: durante os meses de inverno o trem de Orta não opera. Neste caso, se acessará a vila medieval caminhando aproximadamente 800 metros desde o estacionamento do ônibus.'] },
    //         { id: 7, title: 'Verona - Veneza', items: ['Teremos a oportunidade de conhecer a cidade de Romeu e Julieta. Depois sairemos para VENEZA –Chegada por volta das 13h-. Faremos o Traslado de barco até a região da Praça de São Marcos onde visitaremos a pé, com guia local, a cidade dos canais. Você também poderá admirar o trabalho em cristal de Murano. Posteriormente tempo livre, que tal um passeio de gôndola? Hospedagem na região de Mestre.'] },
    //         { id: 8, title: 'Nápoles - Capri - Pompéia - Romra', items: ['Embarcamos para CAPRI, após uma travessia de 45 minutos chegamos a esta ilha famosa por sua beleza natural e estilo de vida luxuoso. Se desejar, pode pegar o barco para a Gruta Branca ou o funicular para a capital de Capri e visitar os jardins de Augusto com vista panorâmica. Voltamos para NAPOLES, onde você tem algum tempo para passear e almoçar. À tarde, conhecemos POMPEIA (entrada e visita com guia local incluído), a próspera cidade reduzida a cinzas e escombros quando o Monte Vesúvio entrou em erupção em 79 d.C. Continuação para ROMA, onde chegamos no final da tarde.'] },
    //         { id: 9, title: 'Roma', items: ['Dia livre em Roma para desfrutar a Cidade.'] },
    //         { id: 10, title: 'Retorno para SP', items: ['Transfer para o aeroporto e retorno para São Paulo.'] },
    //     ],
    //     incluso: ['Transfer', 'hospedagem com Hotel + Café da manhã.', 'Tickets para passeios e transportes', 'Guia em português'],
    //     naoIncluso: ['Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    // },

    {
        id: 303,
        urlName: 'leste-europeu',
        url: '/pacote/leste-europeu',
        nome: 'Leste Europeu',
        pais: 'Praga + Viena + Budapeste',
        img: 'leste-europeu.webp',
        description: '10 dias',
        valor: '22.500',
        dias: [
            { id: 1, title: 'Chegada em Praga', items: ['Recepção e assistência no Aeroporto.', 'Transfer para o Hotel, para descanso. '] },
            { id: 2, title: 'Praga', items: ['Após café da manhã. Explorar esta cidade (Relógio Astronômico) e acompanhar-nos num passeio para conhecer a Catedral de São Vito , Callejon de Ouro (Percurso do Castelo).', 'Faremos uma pausa para o Almoço, no Café onde Franz Kafka e Albert Einstein frequentavam.', 'Depois iremos para a Torre em frente a ponte do Rei Carlos e a Biblioteca Nacional, prefeitura de Praga, no Elevador que nunca para.', 'Encerraremos nosso roteiro em um Mirante pouco conhecido com uma vista incrível da Cidade.'] },
            { id: 3, title: 'Praga', items: ['Após café da manhã. Passeio pelo bairro Judeu, incluindo a ida ao Cemiterio Judeu antigo e as Sinagogas.', 'Faremos uma pausa para o Almoço em um restaurante Georgiano bem típico. Depois iremos ao Museu da Alquimia e ao muro de John Lennon, símbolo de liberdade.', 'm breve passeio pelo Bairro.', 'Após este passeio, encerraremos nosso dia levando-os para o Hotel.'] },
            { id: 4, title: 'Praga', items: ['Após o Café da manhã, chegaremos no bairro de Malastrana, passando pela igreja do menino Jesus de Praga, Kampa e terminando o passeio no Café Restaurante Slavia, onde teve visitantes como Karel Čapek, Jorge Amado, Pablo Neruda,  Miloš Forman,  Emil Kusturica entre outros, ao lado do Café é o Theatro Nacional.', 'Tarde Livres para descanso.'] },
            { id: 5, title: 'Ida para Viena', items: ['Ida de Praga para Viena de trem - Chegada entre o fim da manhã e o início da tarde, faremos o check in no hotel e depois de um breve descanso, faremos, uma visita ao Palácio de Schoenbrunn que é o maior ponto turístico de Viena. Trata-se de um palácio grandioso, um pouco afastado do centro da cidade e projetado para ser tão opulento quanto Versalhes, na França. Um passeio tranquilo e estimulante.', 'Final da Tarde: Visita ao Prater para foto na famosa Roda Gigante. Hospedagem.'] },
            { id: 6, title: 'Viena', items: ['Após o café da manhã, vamos conhecer a Ringstraße (com o Museu de Belas Artes, o Parlamento, a Prefeitura, a Universidade).', 'De tarde Faremos um passeio pelo centro histórico, conheceremos a Catedral Stephandom e o Museum Albertina e outros pontos da Cidade, assim como o conjunto de prédios com sua bela arquitetura.'] },
            { id: 7, title: 'Ida para Budapeste', items: ['Partiremos para Budapeste.', 'Check In no Hotel e Dia livre sem atividades para descanso.'] },
            { id: 8, title: 'Ida para Budapeste', items: ['Faremos um passeio para Basílica de Esztergom com passagem a Vysegrad e Szenterndre Cidades vizinhas de Budapeste. Um passeio histórico incrível que adentra na história da região.'] },
            { id: 9, title: 'Budapeste', items: ['Café da manhã Logo após o Café da manhã. Visita guiada pela cidade atravessando o lado de Buda e Peste, os dois lados da Cidade que através da Ponte forma Budapeste. Neste passeio incluirá visitações como ida ao castelo e passagem pelo Parlamento.', 'Visita a Estátua do Puskas, Metro Opera, segunda estação de metrô mais antiga do mundo.', 'Iremos ao Hotel, para descansar e depois um belo Jantar pelo Danúbio. O jantar buffet inclui todos os pratos típicos da Hungria: em um cruzeiro pelo Danúbio.'] },
            { id: 10, title: 'Retorno para SP', items: ['Transfer para o aeroporto e retorno para São Paulo.'] },
        ],
        incluso: ['Transportes entre países dentro da Europa', 'Inclui hospedagem com Hotel + Café da manhã', 'ingressos e transfer para os passeios citados', 'Guia em português'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', 'Gorjeta'],
    },

    // America

    {
        id: 401,
        urlName: 'atacama',
        url: '/pacote/atacama',
        nome: 'Chile',
        pais: 'Atacama + Santiago',
        img: 'atacama.webp',
        description: '8 dias',
        valor: '15.500',
        dias: [
            { id: 1, title: 'Brasil - Santiago', items: ['Apresentação no aeroporto de sua cidade para embarque com destino a santiago. Chegada, recepção e translado ao hotel escolhido.', 'Restante do dia livre para realizar atividades pessoais.'] },
            { id: 2, title: 'Santiago (city tour)', items: ['Saída para realizar um belo passeio pelos principais pontos turísticos da cidade de santiago.', 'Apreciaremos o contraste de sua rica história e suas modernas e imponentes construções.', 'O Palácio de La Moneda, a Plaza de Armas, o Cerro de Santa Lucía, o Parque Metropolitano e os exclusivos bairros residenciais de vitacura e las condes fazem parte deste inesquecível passeio. Retorno ao hotel e restante do dia livre para realizar atividades pessoais.'] },
            { id: 3, title: 'San Pedro de Atacama', items: ['Chegando no aeroporto em Calama, translado a San Pedro de Atacama.', 'Avistaremos a Cordilheira de Sal no caminho, única vista panorâmica do grande salar e ao fundo o gradioso vulcão Licancabur.', 'Chegando ao hotel em San Pedro de Atacama, hospedagem.'] },
            { id: 4, title: 'San Pedro de Atacama - Vale da Lua e Vale da Morte', items: ['Partiremos para conhecer os vales da Morte e da Lua, até a Cordilheira do Sal.', 'Esta cordilheira fica entre os Andes e o Domeyko, onde encontraremos o Vale dos Dinossauros.', 'O passeio inicia entrando em um cânion estreito e rodeado por altos paredões, para depois chegar ao Vale da Morte, lugar com belas formações e cores.', 'Caminharemos entre as dunas e cortes de montanhas formados pelas erosões da água e do vento.', 'Em seguida, continuaremos para o Vale da Lua, conhecido antigamente como “Las Salinas”, formado por uma depressão de solo salino cercada de pequenos morros com lindas formas e cores, um espetáculo geológico com formas que se assemelham à superfície lunar.', 'Conheceremos suas formações, sal grosso, selenito e a argila que formam o centro do Vale da Lua.', 'Visitaremos a cratera central e o anfiteatro.', 'Avistaremos as estátuas de sal conhecidas como "As Três Marias" e finalizaremos, com o mirador de Kari Uno, onde admiraremos o pôr do sol em todo seu esplendor. Retornaremos ao hotel.', 'Hospedagem.'] },
            { id: 5, title: 'San Pedro de Atacama - Salar de Atacama, Lagoas Altiplânicas, Toconao e Socaire', items: ['Partiremos em direção ao Salar do Atacama (Reserva Nacional Los Flamencos, lagoa Chaxa), área protegida que apresenta blocos de sal e lagoas com sal em formação, habitada por aves típicas deste meio ambiente e flamingos.', 'Cruzaremos esse sítio por uma trilha para conhecer as lagunas do salar.', 'Continuaremos a Socaire, uma aldeia de origem pré-hispânica que se destaca por seu tradicional cultivo em terraços agrícolas e casas típicas do estilo atacamenho: paredes de pedra e teto de barro e madeira.', 'Visitaremos as lagoas Miscanti e Miñiques, localizadas a 4.200 metros, na base dos vulcões da Cordilheira dos Andes de águas azuladas e habitadas por aves andinas.', 'Em seguida, perto de San Pedro de Atacama visitaremos o povoado de Toconao, pequeno povoado colonial com pitorescas construções de pedra liparita, seu campanário e a antiga igreja de San Lucas.', 'Conheceremos o artesanato local.', 'Almoçaremos no caminho. Retornaremos a San Pedro.', 'Hospedagem.'] },
            { id: 6, title: 'San Pedro de Atacama - Laguna Cejar, Ojos del Salar e Tebinquinche', items: ['Sairemos para conhecer a Laguna Cejar, de inexplicável água cor turquesa e cujas margens estão cercadas por formações de cristais de sal.', 'Durante a visita teremos uma boa oportunidade para avistar flamingos.', 'Seguiremos para conhecer ojos del salar, duas poças de água doce que se encontram uma ao lado da outra e alimentados por águas subterrâneas.', 'Continuaremos a Laguna Tebenquiche onde contemplaremos a brancura do sal todo o reflexo dos Andes. Retornaremos ao hotel.', 'Hospedagem.'] },
            { id: 7, title: 'San Pedro de Atacama - Geysers de Tatio, Povoado de Machuca e Termas de Puritama', items: ['Sairemos para conhecer os Geyseres del Tatio que estão a 4.320m de altitude.', 'Chegando, faremos uma caminhada pelo campo geotérmico, onde observaremos os jatos e colunas de vapor, poças de água borbulhante e altos cumes ao redor, além de alguns exemplos de fauna andina como vizcachas (roedores) e guanacos e a vegetação característica da região andina.', 'Chegaremos ao Poço Rústico, onde desfrutaremos as águas termais que nos oferece a Cordilheira. Faremos ali um piquenique. Retornaremos a San Pedro, visitaremos os Termas de Puritama, ideais para um banho de relaxamento.', 'Suas águas fluem entre as rochas e dão início ao rio de água quente, que tem uma temperatura média de 33°C. O lugar conta com passarelas e toaletes/vestiários em frente a cada piscina natural.', 'Em seguida, visitaremos o povoado de Machuca, região conhecida antigamente pela extração de enxofre e criação de llamas que hoje em dia esse povoado é conhecido por suas casas de barro, madeira e palha, se dedicam ao artesanato local andino.', 'Retornaremos ao hotel.', 'Hospedagem.'] },
            { id: 8, title: 'Retorno', items: ['Translado ao aeroporto em Calama.', 'Fim de nossos serviços.'] },
        ],
        incluso: ['    Transfer', 'Hotel', 'Passeios'],
        naoIncluso: ['Aéreos','Visto', 'Passaporte', 'Seguro viagem', 'Gastos extras', '  Gorjeta para o guia'],
    },
    
    {
        id: 9999,
        urlName: 'brazil',
        url: 'brazil',
        nome: 'Brazil',
        pais: 'Package',
        img: 'riodejaneiro.webp',
        description: 'Come to Brazil',
        btn: 'Buy now'
    },

    {
        id: 999,
        urlName: 'empresa',
        url: '/pacote/empresa',
        nome: 'Empresarial',
        pais: 'Pacote',
        img: 'empresarial.webp',
        description: 'Para eventos empresariais'
    },  
]