import './index.css'

const Separador = () => {
  return (
    <div className='separador'>
      {/* <p>Buscando sempre <strong>o melhor serviço</strong> para o seu conforto.</p> */}
      <img src="/img/vendasBanner.webp" alt="" />
      <div
        className='certificado'
      >
        <p >
          Estamos há mais de 5 anos no mercado. Você poderá conferir o nosso <a href="https://www.instagram.com/airbridgeoficial/" target='_blank'>Instagram</a>, onde estamos atuando há anos. Ao visitar o nosso Instagram, não deixe de conferir os nossos Destaques 'Viajantes' e 'Egito', para ver as experiências dos nossos grupos de viagem. Na semana da viagem, criamos um grupo de WhatsApp com a nossa equipe no Brasil e no Egito para que você tenha suporte 24 horas. O nosso diferencial é realmente termos o controle da operação completa, com uma equipe que entende como os brasileiros gostam de viajar, tratando sempre com muito carinho.
        </p>
        <div>
          <img
            src="https://airbridge.com.br/abexperience/img/certificado.png"
            alt="Certificado Cadastur"
          />
          <p>
            Certificado Cadastur
          </p>
        </div>
      </div>
    </div>
  )
}

export default Separador