import { useEffect } from 'react'
import { useState } from 'react'
import './index.css'


const Index = () => {
  const [language, setLanguage] = useState('us')
  // SCROLL SMOOTH
  const saibamais = (e) => {
    e.preventDefault()
    const distance = getDistanceFromTheTop(e.target) - 60
    /*              Top, Distancia Duracao */ 
    smoothScrollTo(0, distance, 1000)
  }

  const getDistanceFromTheTop = (element) =>{
    const id = element.getAttribute('href')
    return document.querySelector(id).offsetTop
  }

  function smoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();
    duration = typeof duration !== "undefined" ? duration : 400;
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
        return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };
    const timer = setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);
        if (time >= duration) {
        clearInterval(timer);
        }
          window.scroll(newX, newY);
            
        }, 1000 / 60);
  }

  useEffect(() => {
    let msg = '', desc = ''
    if(language === 'br'){
      msg = 'Pacote de viagem para o Brasil'
      desc = 'Descubra pontos turisticos do Brasil, com segurança e bom preço.'
    }else if(language === 'us'){
      msg = 'Brazil travel package'
      desc = 'Discover tourist spots in Brazil, safely and at a good price.'
    }else if(language === 'es'){
      msg = 'Paquete de viaje a brasil'
      desc = 'Descubre puntos turísticos de Brasil, de forma segura y a buen precio.'
    }
    document.querySelector('head meta[property="og:title"]').setAttribute('content', msg)

    document.querySelector('head meta[property="og:image"]').setAttribute('content', 'https://photo620x400.mnstatic.com/087dfd415e06b0651f4528fea6642818/rio-de-janeiro.jpg')

    document.querySelector('head meta[property="og:description"]').setAttribute('content', desc)

    const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
    document.querySelector('head meta[property="og:url"]').setAttribute('content', url)

  }, [language])

  const enviarMsg = (e) => {
    e.preventDefault()
    let msg = ''
    if(language === 'br'){
      msg = 'Olá, gostaria de saber sobre o pacote de viagem para o Brasil'
    }else if(language === 'us'){
      msg = 'Hello, I would like to know about the travel package to Brazil'
    }else if(language === 'es'){
      msg = 'Hola, me gustaría saber sobre el paquete de viaje a Brasil.'
    }
    window.open(`https://wa.me/5511915638609?text=${msg}`, "_blank");
  }  

  const openTranslate = () => {
    document.querySelector('#translate').classList.toggle('show')
  }

  return (
    <div className='brazil'>
      <nav>
        <ul onClick={openTranslate} id="translate" className='show'>
          <span title="Translate this Page"><img src={`https://countryflagsapi.com/png/${language}`} alt='' crossOrigin="anonymous"/> {language}</span> 
          <li onClick={() => setLanguage('br')} title="Traduzir para o Português">
            <img src="https://countryflagsapi.com/png/br" alt="Bandeira do Brasil" crossOrigin="anonymous"/>Português (br)
          </li>

          <li onClick={() => setLanguage('us')}     title="Translate to English">
            <img src="https://countryflagsapi.com/png/us" alt="USA flag" crossOrigin="anonymous"/>English (us)
          </li>

          <li onClick={() => setLanguage('es')}
          title="Traducir al Español">
            <img src="https://countryflagsapi.com/png/es" alt="bandera Española" crossOrigin="anonymous"/>Español
          </li>
        </ul>
      </nav>
      <header className="parallax-header">

        <div className="container">

          {
            language === 'br' &&
            <div className="header-content">
              <h3>Venha conhecer o  <span className="enfase-color">BRASIL</span>  e visite os melhores roteiros!</h3>
              <h4>Descubra o Brasil.</h4>
              <a href="#saibamais" className='nav-link' onClick={saibamais}>
                Saiba mais
              </a>
            </div>
          }

          {
            language === 'us' &&
            <div className="header-content">
              <h3>Come and discover <span className="enfase-color">BRAZIL</span>  and visit the best itineraries!</h3>
              <h4>Discover Brazil.</h4>
              <a href="#saibamais" className='nav-link' onClick={saibamais}>
              know more
              </a>
            </div>
          }
          {
            language === 'es' &&
            <div className="header-content">
              <h3>¡Ven a descubrir <span className="enfase-color">BRASIL</span>  y visita los mejores itinerarios!</h3>
              <h4>Descubre Brasil.</h4>
              <a href="#saibamais" className='nav-link' onClick={saibamais}>
                Sepa mas.
              </a>
            </div>
          }
        </div>
      </header>

      <div className="container">

        <div className="content row">
          {language === 'br' && <h2>Brasil</h2>}
          {language === 'us' && <h2>Brazil</h2>}
          {language === 'es' && <h2>Brasil</h2>}

          {
            language === 'br' && 
            <div className="left">
              <p className='text-title'>Uma breve história</p>
              <p>
                Brasil foi colonia de Portugual de 1500 à 1815. Em 1815 o Brasil foi designado Reino de Portugal, Brasil e Algarves, sendo o Rio de Janeiro sede da administração do reino. O período Imperial vai de 1822, com a independência do Brasil, até 1889, com a proclamação da República, e é dividido em três fases principais: o Primeiro Reinado (1822-1831), o Período Regencial (1831-1840) e o Segundo Reinado (1840-1889). Embora, desde 1815 que o Brasil tornara-se Reino de Portugal, Brasil e Algarves, como consequência direta da transferência Corte para o Rio de Janeiro.
              </p>
              <p>
                A cultura brasileira, assim como a formação étnica do povo brasileiro, é vasta e diversa. Nossos hábitos culturais receberam elementos e influências de povos indígenas, africanos, portugueses, espanhóis, italianos e japoneses, entre outros, devido à colonização, à imigração e aos povos que já habitavam aqui.São elementos característicos da cultura brasileira a música popular, a literatura, a culinária, as festas tradicionais nacionais, como o Carnaval, e as festas tradicionais locais, como as Cavalhadas de Pirenópolis, em Goiás, e o Festival de Parintins, no Amazonas. A religião, como elemento cultural, também sofreu miscigenação, formando o que chamamos de sincretismo religioso. O sincretismo religioso brasileiro reúne elementos do candomblé, do cristianismo e das religiões indígenas, formando uma concepção religiosa plural.
              </p>

              <p>
                Em mais de 500 anos de história, a culinária brasileira é resultado de uma grande mistura de tradições, ingredientes e alimentos que foram introduzidos não só pela população nativa indígena como por imigração deste período. Cada região do país tem sua peculiaridade gastronômica e sua culinária adaptada ao clima e ao local.
              </p>

              <p className='text-title'>Destinos turísticos imperdíveis</p>
              <p>
              Rio de Janeiro, Minas Gerais, São Paulo, Bahia, Pernambuco, Paraíba, Rio Grande do Norte, Alagoas, Ceará, Maranhão, Sergipe, Tocantins, Pará, Amazonas, Goiás, Mato Grosso do Sul, Rio Grande do Sul, Santa Catarina, Paraná.
              </p>
            </div>
          }

          {language === 'us' && 
            <div className="left">
              <p className='text-title'>Curiosity of Brazil</p>
              <p>
                Brazil was a colony of Portugal from 1500 to 1815. In 1815 Brazil was designated the Kingdom of Portugal, Brazil and the Algarves, with Rio de Janeiro being the seat of the kingdom's administration. The Imperial period runs from 1822, with the independence of Brazil, until 1889, with the proclamation of the Republic, and is divided into three main phases: the First Reign (1822-1831), the Regency Period (1831-1840) and the Second Reign (1840-1889). Although, since 1815, Brazil had become the Kingdom of Portugal, Brazil and the Algarves, as a direct consequence of the transfer of the Court to Rio de Janeiro.
              </p>
              <p>
                Brazilian culture, like the ethnic background of the Brazilian people, is vast and diverse. Our cultural habits received elements and influences from indigenous, African, Portuguese, Spanish, Italian and Japanese peoples, among others, due to colonization, immigration and the people who already lived here. Characteristic elements of Brazilian culture are popular music, literature , cuisine, traditional national festivals, such as Carnival, and traditional local festivals, such as the Cavalhadas de Pirenópolis, in Goiás, and the Parintins Festival, in Amazonas. Religion, as a cultural element, also underwent miscegenation, forming what we call religious syncretism. Brazilian religious syncretism brings together elements of Candomblé, Christianity and indigenous religions, forming a plural religious conception.
              </p>

              <p>
                In over 500 years of history, Brazilian cuisine is the result of a great mix of traditions, ingredients and foods that were introduced not only by the native indigenous population but also by immigration from this period. Each region of the country has its gastronomic peculiarity and its cuisine adapted to the climate and location.
              </p>

              <p className='text-title'>Must-see tourist destinations</p>
              <p>
              Rio de Janeiro, Minas Gerais, São Paulo, Bahia, Pernambuco, Paraíba, Rio Grande do Norte, Alagoas, Ceará, Maranhão, Sergipe, Tocantins, Pará, Amazonas, Goiás, Mato Grosso do Sul, Rio Grande do Sul, Santa Catarina, Paraná.
              </p>
            </div>
          }

          {language === 'es' && 
            <div className="left">
              <p className='text-title'>Curiosidades de Brasil</p>
              <p>
                Brasil fue una colonia de Portugal desde 1500 hasta 1815. En 1815, Brasil fue designado Reino de Portugal, Brasil y los Algarves, siendo Río de Janeiro la sede de la administración del reino. El período Imperial se extiende desde 1822, con la independencia de Brasil, hasta 1889, con la proclamación de la República, y se divide en tres fases principales: el Primer Reinado (1822-1831), el Período de Regencia (1831-1840) y el Segundo Reinado (1840-1889). Aunque, desde 1815, Brasil se había convertido en el Reino de Portugal, Brasil y los Algarves, como consecuencia directa del traslado de la Corte a Río de Janeiro.
              </p>
              <p>
                La cultura brasileña, al igual que el origen étnico del pueblo brasileño, es vasta y diversa. Nuestros hábitos culturales recibieron elementos e influencias de los pueblos indígenas, africanos, portugueses, españoles, italianos y japoneses, entre otros, debido a la colonización, la inmigración y la gente que ya vivía aquí Elementos característicos de la cultura brasileña son la música popular, la literatura, la cocina, fiestas tradicionales nacionales, como el Carnaval, y fiestas tradicionales locales, como las Cavalhadas de Pirenópolis, en Goiás, y la Fiesta de Parintins, en Amazonas. La religión, como elemento cultural, también sufrió mestizajes, formándose lo que llamamos sincretismo religioso. El sincretismo religioso brasileño reúne elementos del candomblé, el cristianismo y las religiones indígenas, formando una concepción religiosa plural.
              </p>
              <p>
                En más de 500 años de historia, la cocina brasileña es el resultado de una gran mezcla de tradiciones, ingredientes y alimentos que fueron introducidos no solo por la población indígena originaria sino también por la inmigración de este período. Cada región del país tiene su peculiaridad gastronómica y su cocina adaptada al clima y ubicación.
              </p>

              <p className='text-title'>Destinos turísticos imperdibles</p>
              <p>
              Rio de Janeiro, Minas Gerais, São Paulo, Bahia, Pernambuco, Paraíba, Rio Grande do Norte, Alagoas, Ceará, Maranhão, Sergipe, Tocantins, Pará, Amazonas, Goiás, Mato Grosso do Sul, Rio Grande do Sul, Santa Catarina, Paraná.
              </p>
            </div>
          }

          <div className="right">
            <details>
              {language === 'br' && <summary><span>Norte</span> <img src='/img/norte.png' alt=''/></summary>}
              {language === 'us' && <summary><span>North</span> <img src='/img/norte.png' alt=''/></summary>}
              {language === 'es' && <summary><span>Norte</span> <img src='/img/norte.png' alt=''/></summary>}
              <ul>
                <li> 
                  <span>Tocantins</span>
                  <ul>
                    <li>Jalapão</li>
                  </ul>
                </li>
                <li> 
                  <span>Pará</span>
                  <ul>
                    <li>Alter do Chão (Santarém)</li>
                  </ul>
                </li>
                <li> 
                  <span>Amazonas</span>
                  <ul>
                    <li>Manaus</li>
                  </ul>
                </li>
              </ul>
            </details>

            <details>
              {language === 'br' && <summary><span>Nordeste </span> <img src='/img/nordeste.png' alt=''/></summary>}
              {language === 'us' && <summary><span>North East </span> <img src='/img/nordeste.png' alt=''/></summary>}
              {language === 'es' && <summary><span>Noreste </span> <img src='/img/nordeste.png' alt=''/></summary>}
              <ul>
                <li> 
                  <span>Bahia</span>
                  <ul>
                    <li>Lençóis (Chapada Diamantina)</li>
                    <li>Barra Grande</li>
                    <li>Itacaré</li>
                    <li>Trancoso</li>
                    <li>Arraial d’Ajuda (Porto Seguro)</li>
                    <li>Caraíva (Porto Seguro)</li>
                    <li>Morro de São Paulo</li>
                    <li>Praia do Forte (Mata de São João)</li>
                  </ul>
                </li>
                <li> 
                  <span>Pernambuco</span>
                  <ul>
                    <li>Fernando de Noronha</li>
                    <li>Porto de Galinhas (Ipojuca)</li>
                    <li>Tamandaré</li>
                    <li>Olinda</li>
                  </ul>
                </li>
                <li> 
                  <span>Paraíba</span>
                  <ul>
                    <li>João Pessoa</li>
                  </ul>
                </li>
                <li> 
                  <span>Rio Grande do Norte</span>
                  <ul>
                    <li>São Miguel do Gostoso</li>
                    <li>Praia da Pipa (Tibau do Sul)</li>
                    <li>Natal</li>
                  </ul>
                </li>
                <li> 
                  <span>Alagoas</span>
                  <ul>
                    <li>São Miguel dos Milagres</li>
                    <li>Maceió</li>
                    <li>Maragogi</li>
                  </ul>
                </li>
                <li> 
                  <span>Ceará</span>
                  <ul>
                    <li>Jericoacoara</li>
                  </ul>
                </li>
                <li> 
                  <span>Maranhão</span>
                  <ul>
                    <li>Lençóis Maranhenses</li>
                  </ul>
                </li>
                <li> 
                  <span>Sergipe</span>
                  <ul>
                    <li>Aracaju</li>
                  </ul>
                </li>
              </ul>
            </details>

            <details>
              {language === 'br' && <summary><span>Centro Oeste</span><img src='/img/centrooeste.png' alt=''/></summary>}
              {language === 'us' && <summary><span>Midwest</span><img src='/img/centrooeste.png' alt=''/></summary>}
              {language === 'es' && <summary><span>Centro Oeste</span><img src='/img/centrooeste.png' alt=''/></summary>}
              <ul>
                <li> 
                  <span>Mato Grosso do Sul</span>
                  <ul>
                    <li>Bonito</li>
                  </ul>
                </li>
                <li> 
                  <span>Goiás</span>
                  <ul>
                    <li>Alto Paraíso (Chapada dos Veadeiros)</li>
                    <li>Pirenópolis</li>
                  </ul>
                </li>
              </ul>
            </details>

            <details>
              {language === 'br' && <summary><span>Sudeste</span><img src='/img/sudeste.png' alt=''/></summary>}
              {language === 'us' && <summary><span>Southeast</span><img src='/img/sudeste.png' alt=''/></summary>}
              {language === 'es' && <summary><span>Sureste</span><img src='/img/sudeste.png' alt=''/></summary>}
              <ul>
                <li> 
                  <span>Rio de Janeiro</span>
                  <ul>
                    <li>Rio de Janeiro</li>
                    <li>Ilha Grande</li>
                    <li>Petrópolis</li>
                    <li>Paraty</li>
                    <li>Visconde de Mauá</li>
                  </ul>
                </li>
                <li> 
                  <span>Minas Gerais</span>
                  <ul>
                    <li>Ouro Preto</li>
                    <li>Belo Horizonte</li>
                    <li>Poços de Caldas</li>
                    <li>Monte Verde</li>
                    <li>Inhotim (Brumadinho)</li>
                  </ul>
                </li>
                <li> 
                  <span>São Paulo</span>
                  <ul>
                    <li>Olímpia</li>
                    <li>Brotas</li>
                  </ul>
                </li>
              </ul>
            </details>

            <details>
              {language === 'br' && <summary><span>Sul</span><img src='/img/sul.png' alt=''/></summary>}
              {language === 'us' && <summary><span>South</span><img src='/img/sul.png' alt=''/></summary>}
              {language === 'es' && <summary><span>Sur</span><img src='/img/sul.png' alt=''/></summary>}
              <ul>
                <li> 
                  <span>Santa Catarina</span>
                  <ul>
                    <li>Florianópolis</li>
                    <li>Blumenau</li>
                    <li>Urubici</li>
                    <li>Pomerode</li>
                  </ul>
                </li>
                <li> 
                  <span>Rio Grande do Sul</span>
                  <ul>
                    <li>Gramado</li>
                    <li>Bento Gonçalves</li>
                    <li>Cambará do Sul</li>
                    <li>Canela</li>
                  </ul>
                </li>
                <li> 
                  <span>Paraná</span>
                  <ul>
                    <li>Curitiba</li>
                    <li>Foz do Iguaçu</li>
                  </ul>
                </li>
              </ul>
            </details>
                
          </div>
        </div>

        <div className="content">
          <div className='images'>
            <div>
              <img src="https://images.unsplash.com/photo-1539966797022-ead5d7d43db0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" alt="Rio de Janeiro" title='Rio de Janeiro' />
              <p>Rio de Janeiro</p>
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1583275479278-7bc72a16c2ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" alt="Rio de Janeiro" title='Rio de Janeiro' />
              <p>Rio de Janeiro</p>
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1629228420074-c5a87b09ccb7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" alt="Morro de São Paulo - Bahia" title='Morro de São Paulo - Bahia' />
              <p>Morro de São Paulo - Bahia</p>
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1625794858032-a874dff57549?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" alt="Arraial d’Ajuda (Porto Seguro) - Bahia" title='Arraial d’Ajuda (Porto Seguro) - Bahia' />
              <p>Arraial d’Ajuda (Porto Seguro) - Bahia </p>
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1650623598326-7ced3f5e2bbb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80" alt="Lençóis Maranhenses - Maranhão" title='Lençóis Maranhenses - Maranhão' />
              <p>Lençóis Maranhenses - Maranhão</p>
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1598837218686-a456fdaa5cf3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Manaus - Amazonas" title='Manaus - Amazonas' />
              <p>Manaus - Amazonas</p>
            </div>
            <div>
              <img src="https://www.segueviagem.com.br/wp-content/uploads/2019/11/ms_bonito_gruta_do_lago_azul_credito_shutterstock_1019318335_gruta-lago-azul_vinicius-bacarinshutterstock-com_-2-1200x675.jpg" alt="Bonito - Mato Grosso do Sul" title='Bonito - Mato Grosso do Sul' />
              <p>Bonito - Mato Grosso do Sul</p>
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1501072507980-6c49c465932f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Foz do Iguaçu- Paraná" title='Foz do Iguaçu- Paraná' />
              <p>Foz do Iguaçu- Paraná</p>
            </div>
          </div>
        </div>

        <div className='link-button'>
          <a href="#saibamais" className='nav-link' onClick={saibamais}>
              {language === 'br' && 'Adquirir'}
              {language === 'us' && 'Purchase'}
              {language === 'es' && 'Compra'}
          </a>
        </div>
      </div>

      <main>
        <div className='content'id="saibamais">
          {language === 'br' && <h2>Pacote</h2>}
          {language === 'us' && <h2>Package</h2>}
          {language === 'es' && <h2>Pacot</h2>}
        </div>

        <article>       

          {language === 'br' && 
            <section className='inclui'>
              <div>
                <h3>Pacote inclui</h3>
                <ul>
                  <li>Transfer</li>
                  <li>Hotel</li>
                  <li>Guia</li>
                  <li>Seguraça</li>
                </ul>
                <h3>Pacote não inclui</h3>
                <ul>  
                  <li>Passagem aérea de ida e volta</li>
                  <li>Seguro viagem</li>
                  <li>Gastos extras</li>
                </ul>
              </div>
            </section>
          }
          {language === 'us' && 
            <section className='inclui'>
              <div>
                <h3>Package includes</h3>
                <ul>
                  <li>Transfer</li>
                  <li>Hotel</li>
                  <li>Guide</li>
                  <li>Security</li>
                </ul>
                <h3>Package not include</h3>
                <ul>
                  <li>Round-trip airfare</li>
                  <li>Travel insurance</li>
                  <li>Extra expenses</li>
                </ul>
              </div>
            </section>
          }
          {language === 'es' && 
            <section className='inclui'>
              <div>
                <h3>El pacot incluye</h3>
                <ul>
                  <li>Transferir</li>
                  <li>Hotel</li>
                  <li>Guía</li>
                  <li>Seguridad</li>
                </ul>
                <h3>El pacot no incluye</h3>
                <ul>
                  <li>Pasaje aéreo de ida y vuelta</li>
                  <li>Viaje seguro</li>
                  <li>Gastos extras</li>
                </ul>
              </div>
            </section>
          }
          

        </article>

        <div className='button-link'>
          <a href="#saibamais" className='nav-link' onClick={enviarMsg}>
            {language === 'br' && 'Faça seu pedido'}
            {language === 'us' && 'Make your wish'}
            {language === 'es' && 'Hacer su pedido'}
          </a>
        </div>
        
      </main>

      <button onClick={enviarMsg}><img src="/img/whatsapp.webp" alt="Entre em contato por WhatsApp" title='Entre em contato via WhatsApp' /></button>

      <div className="container">
        <div className="content album">
          {language === 'br' && <h2>Um pouco mais</h2>}
          {language === 'us' && <h2>A little more</h2>}
          {language === 'es' && <h2>Un poco más</h2>}
          <div className='images'>
            <div>
              <img src="https://englishlive.ef.com/pt-br/blog/wp-content/uploads/sites/16/2020/02/carnaval-de-rua-em-ingles.jpg" alt="Carnaval" title='Carnaval' />
              <p>Carnaval</p>
            </div>
            <div>
              <img src="https://maracana-web-prod-bkt.s3.amazonaws.com/images/posts/9-nota-oficial-complexo-maracana.png" alt="Maracanã" title='Maracanã' />
              <p>Maracanã</p>
            </div>
            <div>
              <img src="https://www.jornalopcao.com.br/wp-content/uploads/2018/05/festa-junina-2.jpg" alt="Festa Junina" title='Festa Junina' />
              <p>Festa Junina</p>
            </div>
            <div>
              <img src="https://www.sabornamesa.com.br/media/k2/items/cache/b7e870fe16253b03d4f5e4eca7c887cf_XL.jpg" alt="Açai" title='Açai' />
              <p>Açai</p>
            </div>
            <div>
              <img src="https://www.penaestrada.blog.br/wp-content/uploads/2021/02/comidas-tipicas-do-brasil-cozinha.jpg" alt="Coxinha" title='Coxinha' />
              <p>Coxinha</p>
            </div>
            <div>
              <img src="https://reallylearnportuguese.com/wp-content/uploads/2022/02/feijoada.jpg" alt="Feijoada" title='Feijoada' />
              <p>Feijoada</p>
            </div>
            <div>
              <img src="https://www.saboravida.com.br/wp-content/uploads/2019/10/veja-5-comidas-brasileiras-amadas-pelos-turistas.jpg" alt="Brigadeiro" title='Brigadeiro' />
              <p>Brigadeiro</p>
            </div>
            <div>
              <img src="https://a.cdn-hotels.com/gdcs/production161/d154/ad76624f-ad5b-4683-969f-c4f14ecd1abe.jpg" alt="Pão de Queijo" title='Pão de Queijo' />
              <p>Pão de Queijo</p>
            </div>
            <div>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsE9oEr2lew57y5cQlvz9kfsngus7lMDp-Tw&usqp=CAU" alt="Milho" title='Milho' />
              <p>Milho</p>
            </div>
            <div>
              <img src="https://www.accademiamacelleriaitaliana.it/wp-content/uploads/2016/11/265759-churrasco-1024x768.jpg" alt="Churrasco" title='Churrasco' />
              <p>Churrasco</p>
            </div>
            <div>
              <img src="https://www.brasilcultura.com.br/wp-content/uploads/2014/07/acaraje-678x381.jpg" alt="Acarajé" title='Acarajé' />
              <p>Acarajé</p>
            </div>
            <div>
              <img src="https://s2.glbimg.com/p_G3EUPzz-n_AKSa1qDdPaSWmLk=/512x320/smart/e.glbimg.com/og/ed/f/original/2015/04/18/cc40_historiade_1.jpg" alt="Arroz Doce" title='Arroz Doce' />
              <p>Arroz Doce</p>
            </div>
            <div>
              <img src="https://deixaeuviajar.com.br/wp-content/uploads/2018/06/PETROPOLIS-1.jpg" alt="Petrópolis - Rio de Janeiro" title='Petrópolis - Rio de Janeiro' />
              <p>Petrópolis - Rio de Janeiro</p>
            </div>
            <div>
              <img src="https://gooutside.com.br/wp-content/uploads/sites/3/2019/10/site-oferece-hospedagem-em-bondinho-no-pao-de-acucar-no-rio-de-janeiro.jpg" alt="Pão de Açucar" title='Pão de Açucar' />
              <p>Pão de Açucar - Rio de Janeiro</p>
            </div>
            <div>
              <img src="https://prefeitura.rio/wp-content/uploads/2021/10/Cristo-DePaula-2.jpg" alt="Cristo Redentor" title='Cristo Redentor' />
              <p>Cristo Redentor - Rio de Janeiro</p>
            </div>
            <div>
              <img src="https://jujunatrip.com/wp-content/uploads/2021/08/dji_0982.jpg" alt="Ilha Grande - Rio de Janeiro" title='Ilha Grande - Rio de Janeiro' />
              <p>Ilha Grande - Rio de Janeiro</p>
            </div>
            <div>
              <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/16/8c/14/06/ipanema.jpg?w=1200&h=1200&s=1" alt="Praia de Ipanema - Rio de Janeiro" title='Praia de Ipanema - Rio de Janeiro' />
              <p>Praia de Ipanema - Rio de Janeiro</p>
            </div>
            <div>
              <img src="https://freewalkertours.com/wp-content/uploads/Escalera-Selaron5-1.jpeg" alt="Escadaria Selarón" title='Escadaria Selarón' />
              <p>Escadaria Selarón</p>
            </div>
            <div>
              <img src="https://a.cdn-hotels.com/gdcs/production127/d1271/c0b10c15-12e3-4c20-b14e-9b4fa0b4371d.jpg?impolicy=fcrop&w=800&h=533&q=medium" alt="Ouro Preto - Minas Gerais" title='Ouro Preto - Minas Gerais' />
              <p>Ouro Preto - Minas Gerais</p>
            </div>
            <div>
              <img src="https://www.segueviagem.com.br/wp-content/uploads/2020/12/shutterstock_1440135593-1200x675.jpg" alt="Belo Horizonte - Minas Gerais" title='Belo Horizonte - Minas Gerais' />
              <p>Belo Horizonte - Minas Gerais</p>
            </div>
            <div>
              <img src="https://www.viagensecaminhos.com/wp-content/uploads/2022/05/jalapao-tocantins-fervedouro-dos-buritis.jpg" alt="Jalapão - Tocantins" title='Jalapão - Tocantins' />
              <p>Jalapão - Tocantins</p>
            </div>
            <div>
              <img src="https://santarem.pa.gov.br/storage/posts/September2021/capa-hnLpB9.jpg" alt="Alter do Chão (Santarém) - Pará" title='Alter do Chão (Santarém) - Pará' />
              <p>Alter do Chão (Santarém) - Pará</p>
            </div>
            <div>
              <img src="https://portalamazonia.com/images/p/33153/rio-amazonas-brasil-0317-1400x800.jpeg" alt="Rio Amazonas - Amazonas" title='Rio Amazonas - Amazonas' />
              <p>Rio Amazonas - Amazonas</p>
            </div>
            <div>
              <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/f6/3d/44/praia-do-leao.jpg?w=600&h=400&s=1" alt="Fernando de Noronha - Pernambuco" title='Fernando de Noronha - Pernambuco' />
              <p>Fernando de Noronha - Pernambuco</p>
            </div>

          </div>
        </div>

        <div className='link-button'>
          <a href="#saibamais" className='nav-link' onClick={saibamais}>
              {language === 'br' && 'Adquirir'}
              {language === 'us' && 'Purchase'}
              {language === 'es' && 'Compra'}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Index