import React from 'react'


import './index.css'
const Notfound = () => {
  return (
    <div className='quem'>
          <h1>Quem Somos? </h1>
          <p>Airbridge Experience é uma empresa focada em proporcionar aos seus clientes uma experiência internacional incrível, tudo muito planejado para você ter momentos inesquecíveis. </p>

          <p>Se você encara viajar como investir em conhecimento e experiências, a Airbridge Experience trará para você os melhores preços do mercado,  combinando planejamento e suporte para que suas viagens ocorram conforme seu desejo.</p>

          <p>Estamos há 5 anos no mercado, proporcionando as melhores memórias possíveis para nossos clientes.</p>
          <span>Voe com a Airbridge Experience!</span>
    </div>
  )
}

export default Notfound