import { createContext, useState } from 'react'

export const PassagemContext = createContext();

export const PassagemContextProvider = ({children}) => {
    const [passagem, setPassagem] = useState([])
    return(
        <PassagemContext.Provider value={{passagem, setPassagem}}>
            {children}
        </PassagemContext.Provider>
    );
};