import './index.css'
import React, { useEffect, useState } from 'react'

import Card from '../../components/cityComponent/Card';
import Separador from '../../components/separador.component/Separador';
// Lista de Pacotes
import Pac from '../pacote.page/pacote/pacote'

function Pacotes() {
  const packages = Pac

  let idImagens = "&client_id=HYBf82ePcjrP5RS8lDKZ7qv_FhbsrGZABguJJ0MegHc"
  let urlImagens = 'https://api.unsplash.com/search/photos?page=1&query='
  const temas = ['Viajar', 'Viagem', 'Jerusalém', 'Egito', 'Turista', 'Dubai', 'Italia', 'Disney', 'Atacama', 'Tailândia', 'Laos', 'China'];
  const [photos, setPhotos] = useState([])
  const [loading, setLoading] = useState(false)
  let photosTest = new Array()

  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      try {
        const res = await fetch(urlImagens + temas[Math.floor(Math.random() * temas.length)] + idImagens)
        const data = await res.json()
        if (data?.results?.length < 1) {
          console.log(data?.results?.length)
          return setPhotos([])
        }
        for (let i = 0; i < 9; i++) {
          photosTest.push(data.results[i])
        }
        setPhotos(photosTest)
        photosTest = new Array()
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  return (
    <div className='pkg' >
      <div className='cities'>
        {
          packages.map((city) => (
            <Card city={city} key={city.id} />
          ))
        }

      </div>

      <Separador />
      <div className='container-imgs'>
        {
          loading &&
          <div id="c-loader"></div>
        }
        {
          !loading &&
          photos.length > 0 &&
          photos?.map((p, i) => (
            <img src={p?.urls?.small} alt="Viagem" key={i} title={`${p?.alt_description} - ${p?.description}`} />
          ))}
      </div>
    </div>
  )
}

export default Pacotes