import { useEffect } from 'react'
import './index.css'


const Jerusalem = () => {
  // SCROLL SMOOTH
  const saibamais = (e) => {
    e.preventDefault()
    const distance = getDistanceFromTheTop(e.target) - 60
    /*              Top, Distancia Duracao */ 
    smoothScrollTo(0, distance, 1000)
  }

  const getDistanceFromTheTop = (element) =>{
    const id = element.getAttribute('href')
    return document.querySelector(id).offsetTop
  }

  function smoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();
    duration = typeof duration !== "undefined" ? duration : 400;
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
        return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };
    const timer = setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);
        if (time >= duration) {
        clearInterval(timer);
        }
          window.scroll(newX, newY);
            
        }, 1000 / 60);
  }

  const enviarMsg = (e) => {
    e.preventDefault()
    const msg = 'Olá, gostaria de saber sobre o pacote de viagem de JERUSALÉM - IL.'
    window.open(`https://wa.me/5511915638609?text=${msg}`, "_blank");
  }

  useEffect(() => {
    let msg = 'Pacote de viagem para Jerusalém', desc = 'Venha descobrir a cidade santa, com preços promocionais e pacotes completos.'
    
    document.querySelector('head meta[property="og:title"]').setAttribute('content', msg)

    document.querySelector('head meta[property="og:image"]').setAttribute('content', 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/27/72/1a.jpg')

    document.querySelector('head meta[property="og:description"]').setAttribute('content', desc)

    const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
    document.querySelector('head meta[property="og:url"]').setAttribute('content', url)

  }, [])
  

  return (
    <div className='pacote'>
      <header className="parallax-header">
        <div className="container">
          <div className="header-content">
            <h3>Mais que uma viagem, uma <span className="enfase-color">experiência única</span>  e indescritível!</h3>
            <h4>Refaça os caminhos icônicos de Jesus.</h4>
            <a href="#saibamais" className='nav-link' onClick={saibamais}>
              Saiba mais
            </a>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="content row">
          <h2>Jerusalém</h2>
          <div className="left">
            <p className='text-title'>Uma breve história</p>
            <p>
              De acordo com a tradição judaica, o rei David de Israel e Judá conquistou Jerusalém no ano 1004 a.C. e a transformou na capital do seu reino unificado. A história de Jerusalém então entrou em um período de esplendor que faria com que séculos mais tarde se tornasse a Cidade Santa para os muçulmanos, judeus e cristãos. 
            </p>
            <p>
              O rei Salomão, filho de Davi, ampliou os muros da cidade e construiu o grande Templo de Jerusalém, destinado a guardar a reverenciada Arca da Aliança, que continha as leis que Javé deu a Moisés no Monte Sinai.
            </p>

            <p>
              Após a destruição do templo durante a invasão da Babilônia, um Segundo Templo começou a ser construído no atual Monte do Templo (ou Esplanada das Mesquitas) que seria concluído durante o reinado de Herodes, o Grande.
            </p>

            <p className='text-title'>Um destino turístico imperdível</p>
            <p>
              Jerusalém é uma cidade de contrastes. Passear por Jerusalém permite que você desfrute de três culturas ao mesmo tempo: no souk muçulmano você sentirá os aromas e a agitação da Arábia, no bairro judeu você testemunhará as mais antigas tradições hebraicas e nos muitos lugares sagrados você testemunhará a fé dos cristãos mais devotos.
            </p>
            <p>
              A história de Jerusalém é o seu maior atrativo e é possível senti-la e lembrá-la em cada recanto da Cidade Santa.
            </p>
            </div>
            <div className="right">
              <details>
                <summary>Santo Sepulcro</summary>
                <p>A Basílica do Santo Sepulcro é um templo cristão localizado no Bairro Cristão da Cidade Antiga de Jerusalém onde, segundo a tradição, Jesus teria sido crucificado, sepultado e, ao terceiro dia, teria ressuscitado. Administrada e repartida entre as igrejas Católica Romana, Católica Ortodoxa, Apostólica Armênia, Ortodoxa Copta, Ortodoxa Siríaca e Ortodoxa Etíope, constitui um dos locais mais sagrados da cristandade.</p>   
            </details>
            <details>
              <summary>Muro das Lamentações</summary>
              <p>O Muro das Lamentações ou Muro Ocidental é o segundo local mais sagrado do judaísmo, atrás somente do Santo dos Santos, no monte do Templo. Trata-se do único vestígio do antigo Templo de Herodes, erguido por Herodes, o Grande no lugar do Templo de Jerusalém inicial. É a parte que restou de um muro de arrimo que servia de sustentação para uma das paredes do edifício principal e que em si mesmo, não integrava o Templo que foi destruído pelo general Tito, que depois se tornaria imperador romano, no ano de 70. Muitos fieis judeus visitam o Muro das Lamentações para orar e depositar seus desejos por escrito. Antes da Guerra dos Seis Dias, em 1967, quando o local e toda a Cidade Velha de Jerusalém foram conquistados por Israel, o local era chamado de Mughrabi Quarter ou o Quarteirão Marroquino, de onde, por ordem do prefeito de Jerusalém, 135 famílias árabes foram removidas para a abertura da esplanada do Muro das Lamentações.</p>
            </details>
            <details>
              <summary>Machaneh Yehudah Market</summary>
              <p>Mercado, vida noturna e sinagoga.</p>
            </details>
            <details>
              <summary>Getsêmani</summary>
              <p>Getsêmani ou Getsémani é um jardim situado no sopé do Monte das Oliveiras, em Jerusalém, onde Jesus e seus discípulos oraram na noite anterior à crucificação de Jesus. De acordo com o Evangelho segundo Lucas, a angústia de Jesus no Getsêmani foi tão profunda que "seu suor tornou-se em grandes gotas de sangue, que corriam até ao chão."</p>
            </details>
            <details>
              <summary>Tower of David</summary>
              <p>A Torre de David, é uma antiga cidadela localizada próxima à entrada do Portão de Jafa na Cidade Velha de Jerusalém. Construído para fortalecer um ponto estrategicamente fraco na defesa da Cidade Velha, a cidade foi construída durante o segundo século a.C. e subsequentemente destruído e reconstruído por, em sucessão, conquistadores cristãos, muçulmanos, mamelucos e otomanos de Jerusalém. Ela contém achados arqueológicos importantes datados de 2 700 anos, é um área popular para eventos, apresentações, concertos e performances de luz e som. O nome "Torre de David" leva ao engano, uma vez que foi construído pelo menos diversas centenas de anos após a datação bíblica do reinado do Rei David.</p>
            </details>
            <details>
              <summary>Old City</summary>
              <p>A chamada Cidade Antiga ou Cidade Velha de Jerusalém é uma área amuralhada em forma rectangular com 0,9 km² na cidade moderna de Jerusalém. Na Cidade Velha encontram-se vários sítios de fundamental importância religiosa, como o Monte do Templo e Muro das Lamentações para os judeus, a Basílica do Santo Sepulcro para os cristãos e o Domo da Rocha e a Mesquita de al-Aqsa para os muçulmanos. A Cidade Antiga e as suas muralhas foram nomeadas pela UNESCO Património Mundial da Humanidade em 1981, por indicação da Jordânia. Devido à história conflitual da cidade e sua soberania indefinida, o país de localização do sítio não está especificado na lista da UNESCO.</p>
            </details>
            <details>
              <summary>Monte das Oliveiras</summary>
              <p>O Monte das Oliveiras é um monte situado a leste da Cidade Antiga de Jerusalém, em Israel, pertencente a uma cadeia de colinas com três picos, dispostos de norte a sul, dos quais o mais alto, at-Tur, se eleva a 818 metros. Recebe seu nome pelas oliveiras que cobriam, antigamente, suas encostas. O Monte das Oliveiras é sagrado para judeus, cristãos e muçulmanos, e muitas tradições estão associadas a ele. Segundo a Bíblia, por exemplo, Jesus teria transmitido ali alguns de seus ensinamentos. A altura do Monte das Oliveiras e as vistas espetaculares que ele apresenta para a Cidade Antiga de Jerusalém e para o Monte do Templo fizeram com que alguns dos mapas e ilustrações mais realistas da região na Antiguidade fossem feitos dos seus cumes. No Monte das Oliveiras está situado o jardim do Getsêmani.</p>
            </details>
            <details>
              <summary>Via Dolorosa Street</summary>
              <p>Via Dolorosa é uma rua na cidade velha de Jerusalém, que começa na Portão do Leão e percorre a parte ocidental da cidade de Jerusalém, terminando na Igreja do Santo Sepulcro. De acordo com a tradição cristã, foi por este caminho que Jesus Cristo carregou a cruz. A rua possui nove das quatorze estações da cruz. As 5 últimas estações estão no interior da Igreja do Santo Sepulcro.</p>
            </details>
            <details>
              <summary>The Garden Tomb Jerusalem</summary>
              <p>Tumba do Jardim é um túmulo talhado na rocha localizado em Jerusalém, que foi descoberto em 1867 e, posteriormente, foi considerado por alguns cristãos - principalmente os protestantes e evangélicos - como o local do sepultamento e ressurreição de Jesus. O jardim é administrado pela Garden Tomb Association, membro da Aliança Evangélica de Israel e da Aliança Evangélica Mundial.</p>
            </details>
            <details>
              <summary>Outros</summary>
              <p>Yad Vashem</p>
              <p>Damascus Gate</p>
              <p>Jaffa Gate</p>
              <p>St. Anne's Church, Jerusalem</p>
              <p>Mesquita de Al-Aqsa</p>
              <p>Sepulcro de Maria</p>
              <p>Maquete da Terra Santa de Jerusalém</p>
              <p>Masada National Park</p>
              <p>The Israel Museum, Jerusalem</p>
              <p>Mar Morto</p>
              <p>...</p>
            </details>
                
            </div>
        </div>
        <div className="content">
          <div className='images'>
            <div>
              <img src="https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcTGbfW-4lImi6dhCeSOJaMLs4mquAQs8o05r3ydFVcu4bfr8V_93fn7pcI-euHUZ1ij4hg4uwOhCsnaIzlXwvDUJw" alt="Santo Sepulcro" title='Santo Sepulcro' />
              <p>Santo Sepulcro</p>
            </div>
            <div>
              <img src="https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcRfcwWc3sXv3rC4GhJyC8EAKGnLWiLUCtEPrON7EoGcMIthK-tGOu_JoqnD49fAHs950H1YOAoaXxo1Ou7Ugpxmyw" alt="Muro das Lamentações" title='Muro das Lamentações' />
              <p>Muro das Lamentações</p>
            </div>
            <div>
              <img src="https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcSvo2MXo3eGqqDdqD5d3pK-h7t-aMLO2_pjVEn7oTrXbwsRSWlqdddN7u8WY8XtRGjhQElKvef8QUmcIoXv-JKVPQ" alt="Machaneh Yehudah Market" title='Machaneh Yehudah Market' />
              <p>Machaneh Yehudah Market</p>
            </div>
            <div>
              <img src="https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcQghLUv6JnqKeBLJB-iQ5K7UgAD-3hoWRf14xJl9z4iDbIVuWBQM2uJYatMe2jN7iP_e68bb7ueyrkRGDoYgACLFQ" alt="Getsêmani" title='Getsêmani' />
              <p>Getsêmani</p>
            </div>
            <div>
              <img src="https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcQgHVgHQto2i4r3OcNXtEo34l0mVoJAbBhPFMDa5Ru7V9rUMyqQJm2CFU3jpfR_eCOAr5iABQtkbbLP18UDHXu_jQ" alt="Monte das Oliveiras" title='Monte das Oliveiras' />
              <p>Monte das Oliveiras</p>
            </div>
            <div>
              <img src="https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcTraEwDzXFB3hNrDy3apMuUgDuWzukUtwKD5x9BRVb6tKfSr5Zm9nfgKG9NRO6qPei4Rx7fV0J2NNcf0-MpRldtEg" alt="Via Dolorosa Street" title='Via Dolorosa Street' />
              <p>Via Dolorosa Street</p>
            </div>
            <div>
              <img src="https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcTAhkxtx1Eo-l6xIfNoWoJglYxqaXqriKz4CLu7nClE0Fb9REiLvQs4_eUM6MrG0sd5BI3PGU4W3OH30mchQ96flA" alt="The Garden Tomb Jerusalem" title='The Garden Tomb Jerusalem' />
              <p>The Garden Tomb Jerusalem</p>
            </div>
            <div>
              <img src="https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcQMExp6v2Pb1b0quky_-q7tMXuw2hO-6eN9NY05RWw0o8rHAbarRDFa1NZN6L6RTpoxRZPBNx-F15eBA0j_y0XCCA" alt="Mar Morto" title='Mar Morto' />
              <p>Mar Morto</p>
            </div>
          </div>
        </div>
        <div className='link-button'>
          <a href="#saibamais" className='nav-link' onClick={saibamais}>
              Adquirir
          </a>
        </div>
      </div>

      <main>
        <div className='content'id="saibamais">
          <h2>Pacote</h2>
        </div>
        <article>
          <section className='preco'>
            <p>10 DIAS</p>
            <p>em jerusalém - IL</p>
            <p>por apenas <span className="text-enfase"><small>R$</small> 13.200 <sup>,00</sup></span></p>

          </section>
          <section className='inclui'>
            <div>
              <p>Pacote para grupo de 10 pessoas</p>
            </div>
            <div>
              <h3>Pacote inclui</h3>
              <ul>
                <li>Transfer</li>
                <li>Hotel</li>
                <li>Passeios</li>
              </ul>
              <h3>Pacote não inclui</h3>
              <ul>
                <li>Passagem aérea de ida e volta</li>
                <li>Seguro viagem</li>
                <li>Gastos extras</li>
                <li>Gorjeta para o guia</li>
              </ul>
            </div>
          </section>

        </article>
        <div className='button-link'>
          <a href="#saibamais" className='nav-link' onClick={enviarMsg}>
            Entre em contato
          </a>
        </div>
        
      </main>

      <button onClick={enviarMsg}><img src="/img/whatsapp.webp" alt="Entre em contato por WhatsApp" title='Entre em contato via WhatsApp' /></button>
    </div>
  )
}

export default Jerusalem